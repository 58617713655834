import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import Modal from "react-modal";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class MoreItemInfoModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { closeModal, item, t } = this.props;
        const { mainColor } = this.props.myShopApiStore;
        return (
            <Modal
                onRequestClose={closeModal}
                isOpen={this.props.modalIsOpen} 
                style={
                    {
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,.75)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 10
                        },
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            padding: 0,
                            maxHeight: '100%',
                            maxWidth: '100%',
                            backgroundColor: "#FFF",
                            border: 'unset',
                            // height: '55vh',
                            // width: '45vw',
                            width: '60%',
                        }
                    }
                }>
                <div className={style.container}>
                    <div className={style.closeBtn} onClick={closeModal}>✕</div>
                    <div className={style.body}>
                        <div className={style.itemImage}><img alt="" src={item.product.image} width="100%" /></div>
                        <div className={style.itemName} style={{color: mainColor}}>{item.product.name}</div>
                        <div className={style.details}>
                            <div className={style.pair}>
                                <div className={style.detail}>
                                    <div className={style.detailTitle}>{t('Amount in a box')}</div>
                                    <div className={style.detailContent}>X</div>
                                </div>
                                <div className={style.detail}>
                                    <div className={style.detailTitle}>{t('Depth')}</div>
                                    <div className={style.detailContent}>X</div>
                                </div>
                            </div>
                            <div className={style.pair}>
                                <div className={style.detail}>
                                    <div className={style.detailTitle}>{t('Length')}</div>
                                    <div className={style.detailContent}>X</div>
                                </div>
                                <div className={style.detail}>
                                    <div className={style.detailTitle}>{t('Color')}</div>
                                    <div className={style.detailContent}>X</div>
                                </div>
                            </div>
                            <div className={style.pair}>
                                <div className={style.detail}>
                                    <div className={style.detailTitle}>{t('Dimensions')}</div>
                                    <div className={style.detailContent}>X</div>
                                </div>
                                <div className={style.detail}>
                                    <div className={style.detailTitle}>{t('SKU')}</div>
                                    <div className={style.detailContent}>X</div>
                                </div>
                            </div>
                            <div className={style.pair}>
                                <div className={style.detail}>
                                    <div className={style.detailTitle}>{t('barcode')}</div>
                                    <div className={style.detailContent}>X</div>
                                </div>
                                <div className={style.detail}>
                                    <div className={style.detailTitle}>{t('Material')}</div>
                                    <div className={style.detailContent}>X</div>
                                </div>
                            </div>
                        </div>
                        <div className={style.comments}>
                            <div className={style.titleComments}>{t('Item comments')}</div>
                            <div className={style.bodyComments}>{item.product.description}</div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(MoreItemInfoModal);
