import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import RingLoader from "react-spinners/RingLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  ${'' /* display: block; */}
  ${'' /* margin-top: 0; */}
  ${'' /* border-color: red; */}
  ${'' /* size: 26; */}
  ${'' /* height: 4; */}
  ${'' /* width: 100; */}
  ${'' /* "#c3f5ff" */}
`;

const Spinner = ({ loading, color, loader }) => {
  return (
    <div className="sweet-loading">
      {
        loader === 'BeatLoader'
        ?
          <BeatLoader color={color} loading={loading} css={override}  />
        :
          <RingLoader color={color} loading={loading} css={override}  />

      }
    </div>
  );
}

export default Spinner;