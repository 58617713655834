import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import Modal from 'react-modal';
import APILoader from 'Components/MainScreen/AppBody/APILoader';
import ValidSMSModal from 'Components/MainScreen/AppBody/ValidSMSModal';
import ThanksModal from 'Components/MainScreen/AppBody/ThanksModal';
import ThanksCouponModal from 'Components/MainScreen/AppBody/ThanksCouponModal';

import ReactPixel from 'react-facebook-pixel';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class PostageModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            apiLoaderIsOpen: false,
            smsModalIsOpen: false,
        }

        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width: '365px',
                height: '260px',
                maxHeight: '300px'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    preLoadApi = async () => {
        const { createSessionResp, makePreorderResp, creditUniqueId, shop} = this.props.myShopApiStore;

        const resp = await makePreorderResp();

        //console.log('p2');
        //console.log(resp);

        if(resp.data.errorCode !== '0'){
            this.props.setMakeOrderErrorModalParams(resp.data.errorCode, resp.data.errorMessage);
            this.props.openMakeOrderErrorModal();
            return false;
        }
        else{
            if(typeof (shop.externalTypeCodename) != 'undefined' && shop.externalTypeCodename == 'comax') {
                //
            } else {
                await createSessionResp(creditUniqueId);
            }
            return true;
        }
    }

    handleCountinueClick = async () => {
        const { closeModal, closeMapModal, nextStep } = this.props;
        const { calcTotalCartPrice, orderData, registerUser, setCreditUniqueId } = this.props.myShopApiStore;

        const cartPrice = calcTotalCartPrice();

        if(orderData.isCredit && cartPrice > 0){ //payment with credit card!
            // const { createSessionResp } = this.props.myShopApiStore;
            // await createSessionResp();
            this.openAPILoader();
            const preorder = await this.preLoadApi();
            this.closeAPILoader();

            if(preorder) {
                closeModal(); //closing postage modal
                closeMapModal();
                this.closeAPILoader();
                nextStep();
            } else {
                closeModal();
            }
        }
        else{ //payment to delivery guy!
            setCreditUniqueId('');
            this.openAPILoader();
            const regResp = await registerUser();
            this.closeAPILoader();

            if(regResp.data){ //valid token
                this.openAPILoader();
                await this.handleMakeOrderCall();
                this.closeAPILoader();

            }
            else{ //invalid token
                this.openSmsModal();
            }
        }
    }

    handleMakeOrderCall = async () => { //make order (-when the payment is to delivery guy!-)
        const { closeModal, openMakeOrderErrorModal, setMakeOrderErrorModalParams,
            openThanksModal,  closeMapModal } = this.props;
        const { makeOrderResp, deliveryCost, orderData } = this.props.myShopApiStore;

        this.openAPILoader()
        const resp = await makeOrderResp();
        console.log("rrresp:",resp)
        this.closeAPILoader();

        closeModal(); //closing postage modal
        if(resp.data.errorCode !== '0'){
            setMakeOrderErrorModalParams(resp.data.errorCode, resp.data.errorMessage);
            openMakeOrderErrorModal();
        }
        else{
            if(process.env.REACT_APP_FACEBOOK_PIXEL != '') ReactPixel.track('Purchase');

            closeMapModal();
            openThanksModal();
        }
    }
    
    openAPILoader = () => {
        this.setState({apiLoaderIsOpen: true})
    }

    closeAPILoader = () => {
        this.setState({apiLoaderIsOpen: false})
    }

    openSmsModal = () => {
        this.setState({smsModalIsOpen: true});
    }

    closeSmsModal = () => {
        this.setState({smsModalIsOpen: false});
    }

    render() {
        
        const { modalIsOpen, closeModal, distOutOfRange, t } = this.props;
        const { getShopLatLng, deliveryCost, hasPostCoupon } = this.props.myShopApiStore;
        return (

            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={closeModal}>
                <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
                <ValidSMSModal modalIsOpen={this.state.smsModalIsOpen} closeModal={this.closeSmsModal}
                                handleMakeOrderCall={this.handleMakeOrderCall} action='Delivery' nextStep={this.props.nextStep}
                />
                {
                    hasPostCoupon
                    ?
                        <ThanksCouponModal closeModal={this.props.closeThanksModal} modalIsOpen={this.props.thanksModalIsOpen} />
                    :
                        <ThanksModal closeModal={this.props.closeThanksModal} modalIsOpen={this.props.thanksModalIsOpen} />
                    

                }

                <div className={style.Container}>
                    <div className={style.CancleXBtn}>
                        <span onClick={closeModal}>✕</span>
                    </div>
                    <div className={style.TextContnet}>
                        <div className={style.Title}>
                            <span>{t('pay attention')}</span>
                        </div>
                        <div className={style.TowRowText}>
                            {
                                distOutOfRange
                                ?
                                    <>
                                        <span>{t('delivery') + ' ' + t('can not be made')}</span>
                                        <span>{t('for requested address')}</span>
                                        {!getShopLatLng() && <span style={{color: '#FF0000', fontSize: '12px', fontWeight: '600'}}> {t('your address can not be found')}</span>}
                                    </>
                                :
                                    <>
                                        <span>{t('delivery cost to your address')}</span>
                                        <span>{t('that ordered')}</span>
                                    </>
                            }
                        </div>

                        <div className={style.Price}>
                        {
                            distOutOfRange
                            ?
                                <>
                                    <span style={{color: '#FFFFFF'}}>.</span>
                                    <span style={{color: '#FFFFFF'}}>.</span>
                                </>
                            :
                                deliveryCost === 0
                                ?
                                    <>
                                        <span>{t('free')}</span>
                                        <span></span>
                                    </>
                                :
                                    <>
                                        <span>{t('currency')}</span>
                                        <span>{deliveryCost.toFixed(2)}</span>
                                    </>
                        }
                        </div>
                        
                    </div>
                    <div className={style.ContinueBtn}>
                        <span onClick={distOutOfRange ? closeModal : this.handleCountinueClick}>{distOutOfRange ? t('back') : t('continue')}</span>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(PostageModal);