import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class WelcomeImageModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content: {
                overflow: "hidden",
                background: 'none',
                /*width: '100%',
                height: '100%',*/
                maxWidth: '1050px',
                maxHeight: '640px',
                marginLeft: 'auto',
                marginRight: 'auto',
                border: 'none',
                inset: '0px',
                marginTop: '140px',
                marginBottom: '140px',
                paddingLeft: '50px',
                paddingRight: '50px'
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    goMenu = () => {
        const { setActiveTopMenu } = this.props.ifaceMngr;
        const { closeModal, closeStore } = this.props;
        const { shop, shopsData } = this.props.myShopApiStore;
        
        if(shop.applicationBlocked == '0') {
            setActiveTopMenu("ShopMenu");
            closeModal();
        }
        if(shop.applicationBlocked == '1' && shopsData && shopsData.shops?.length) closeStore();

    }
    render() {
        const {modalIsOpen } = this.props;
        const { shop, shopsData } = this.props.myShopApiStore;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.goMenu} ariaHideApp={false} shouldCloseOnOverlayClick={shop.applicationBlocked == '0'} shouldCloseOnEsc={shop.applicationBlocked == '0'}>
                {
                    shop.applicationBlocked == '1' && !(shopsData && shopsData.shops?.length) ?
                        ''
                    :
                        <div className={style.cancleBtn} onClick={this.goMenu}>✖</div>
                }
                <div className={style.welcomeImageDiv}>
                    <img className={style.welcomeImage} src={this.props.isMobile ? shop.welcomeImageSite/*shop.welcomeImage*/ : shop.welcomeImageSite} alt='תמונה'/>
                </div>
            </Modal>
        )
    }
}

export default WelcomeImageModal;