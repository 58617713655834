import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class MaxToppingsMsg extends React.Component {
    constructor(props) {
        super(props)
        this.scrollUpRef = React.createRef();
    }

    componentDidMount = () => {
        this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})
      }
    
      componentDidUpdate() {
        this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})
      }
      
    render() {
        const { limitedToppingsNumber, closeMaxToppingMsg, t } = this.props;
        return (
            <div className={style.Container} ref={this.scrollUpRef} onClick={closeMaxToppingMsg}>
                <div>{t('You have reached the maximum amount')}</div>
                {
                
                /* <div>
                    כמות התוספות המקסימלית למוצר זה
                </div>
                <div>
                    היא {limitedToppingsNumber}, כרגע ישנה חריגה בכמות
                </div> */}
            </div>
        )
    }
}

export default withTranslation()(MaxToppingsMsg)
