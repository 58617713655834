import React, { useRef, useEffect } from 'react'
import style from './style.module.css';

const InputFields = ({ isMobile, handleChange, c1, c2, c3, c4 }) => {
    const c1Ref = useRef();
    const c2Ref = useRef();
    const c3Ref = useRef();
    const c4Ref = useRef();

    useEffect(() => {
        if(c1Ref.current){
            c1Ref.current.focus();
        }
    }, [])
    useEffect(() => {
        if(c2Ref.current && c1){
            c2Ref.current.focus();
        }
    }, [c1])
    useEffect(() => {
        if(c3Ref.current && c2){
            c3Ref.current.focus();
        }
    }, [c2])
    useEffect(() => {
        if(c4Ref.current && c3){
            c4Ref.current.focus();
        }
    }, [c3])
    
    useEffect(() => {
        if(c1Ref.current && !c1 && !c2 && !c3 && !c4){
            c1Ref.current.focus();
        }
    })

    return (
        <div className={style.Inputs}>
            <input ref={c1Ref} maxLength={1} name="code1" autoComplete="off" value={c1} type="tel" onChange={handleChange} style={{width: isMobile ? '60px' : '3.5vw'}}/>
            <input ref={c2Ref} maxLength={1} name="code2" autoComplete="off" value={c2} type="tel" onChange={handleChange} style={{width: isMobile ? '60px' : '3.5vw'}}/>
            <input ref={c3Ref} maxLength={1} name="code3" autoComplete="off" value={c3} type="tel" onChange={handleChange} style={{width: isMobile ? '60px' : '3.5vw'}}/>
            <input ref={c4Ref} maxLength={1} name="code4" autoComplete="off" value={c4} type="tel" onChange={handleChange} style={{width: isMobile ? '60px' : '3.5vw'}}/>
        </div>
    )
}

export default InputFields
