import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import CouponModal from "./CouponModal";
import CouponSuccessModal from "./CouponSuccessModal";
import CloseStoreModal from "./CloseStoreModal";
import ProductsAssociatedModal from 'Components/MainScreen/AppBody/ProductsAssociatedModal';

@inject((store) => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class PaymentButtons extends React.Component {
    constructor(props) {
        super(props)
    
        // this.hasCoupon = this.props.myShopApiStore.getCoupon()
        this.state = {
            isCouponModalOpen: false,
            closeStoreModalIsOpen: false,
            successModalIsOpen: false, //coupon success activation
            isMissingProductsModalOpen: false,
            productsAssociatedModalIsOpen: false,
            productsAssociated: null,
            unitTypeSelected: null,
        }
    }

    openProductsAssociatedModal = async () => {
        const {setAssociatedStage} = this.props.myShopApiStore;
        setAssociatedStage(true);
    }
    closeProductsAssociatedModal = () => {
        const { orderData, resetOrderData, setAssociatedStage, isPackItemUpdating,
                isCartItemUpdating,  } = this.props.myShopApiStore;

        setAssociatedStage(false);
        !orderData && resetOrderData(); //clean order data details
        !isPackItemUpdating && !isCartItemUpdating &&
                 this.openPaymentPage();
    }
    
    openSuccessModal = () => { //for coupon success activation
        this.setState({successModalIsOpen: true})
    }

    closeSuccessModal = () => { //for coupon success activation
        this.setState({successModalIsOpen: false})
    }

    openCloseStoreModal = () => { 
        this.setState({closeStoreModalIsOpen: true})
    }

    closeCloseStoreModal = () => { 
        this.setState({closeStoreModalIsOpen: false})
    }

    openCouponModal = () => {
        this.setState({isCouponModalOpen: true})
    }

    closeCouponModal = (isSuccess) => {
        //console.log('closeCouponModal');
        this.setState({isCouponModalOpen: false})

        this.props.myShopApiStore.setCouponPreopened(false);

        if(isSuccess){
            this.openSuccessModal();
        }
    }

    precloseCouponModal = () => {
        this.props.myShopApiStore.setCouponPreopened(false);
    }

    openPaymentPage = () => {
        this.setState({inAssociatedProducts: false});
        const { setActiveTopMenu } = this.props.ifaceMngr;
        setActiveTopMenu("Payment")
    }

    handleGoToPaymentClick = async () => {
        const { resetOrderData, orderData, shop, setSearchValue, checkAssociatedProductsInCart ,getProductsAssociated, checkCartItemPresence } = this.props.myShopApiStore;
        const { orderTypes } = shop;
        const { openMissingProductsModal } = this.props;

        const hasDeliveryOp = orderTypes ? orderTypes.some(ot => ot === 'delivery') : true;
        const hasPickupOp = orderTypes ? orderTypes.some(ot => ot === 'pickup') : true;
        const hasSitOp = orderTypes ? orderTypes.some(ot => ot === 'sit') : true;

        setSearchValue('', true);

        //  create a variable to hold the associated products
        let shopProductsAssociatedIds = checkAssociatedProductsInCart();

        if (shopProductsAssociatedIds.length) {
            await getProductsAssociated();
            //  open the modal
            this.openProductsAssociatedModal();
        }else {
            if(!hasDeliveryOp && !hasPickupOp && !hasSitOp){
                this.openCloseStoreModal();
                return;
            }
            if(!await checkCartItemPresence()) {
                openMissingProductsModal();
                return;
            }
            (!shop.isPriceOrderType) && !orderData && resetOrderData(); //clean order data details
            this.openPaymentPage();
        }
    }

    setUnitTypeSelected = (unitType) => {
        this.setState({unitTypeSelected: unitType})
    }

    //  handle Add associated products
    handleAddAssociated = async (item = null) => {
        const {addToCart, isPackItemUpdating,
            isCartItemUpdating} = this.props.myShopApiStore;
        
        let isDuringOrder = isPackItemUpdating || isCartItemUpdating;
        if(!isDuringOrder){
            addToCart(item, item.unitTypes[0]);            
        }
        
        this.closeProductsAssociatedModal();
    }
    
    render() {
        const { mainColor, shop, inAssociatedStage, associatedProducts, coupons, shopId} = this.props.myShopApiStore;
        const { t } = this.props;

        return (
            <div className={style.Container}>
                <CouponSuccessModal closeModal={this.closeSuccessModal} modalIsOpen={this.state.successModalIsOpen}/>
                <CloseStoreModal closeModal={this.closeCloseStoreModal} modalIsOpen={this.state.closeStoreModalIsOpen}/>
                <ProductsAssociatedModal modalIsOpen={inAssociatedStage}
                        closeModal={this.closeProductsAssociatedModal}
                        itemList={associatedProducts}
                        handleAddClick={this.handleAddAssociated}
                    />

                { (this.state.isCouponModalOpen || this.props.myShopApiStore.couponPreopened) && <CouponModal precloseCouponModal={this.precloseCouponModal} closeModal={this.closeCouponModal}/> }
                <div className={style.PaymentBtns}>
                {
                    shop.isBusiness || !shop.withCoupons
                    ?
                        <div style={{height: "25px", cursor: 'unset'}}/>
                    :
                        <div onClick={(this.state.isCouponModalOpen || coupons.some(coupon => coupon.id === shopId)) ? null : this.openCouponModal} disabled={this.state.isCouponModalOpen || coupons.some(coupon => coupon.id === shopId)} style={{backgroundColor: coupons.some(coupon => coupon.id === shopId) && "rgb(217, 217, 217)", border: `1px solid ${mainColor}`, color: mainColor}}>{t('Do you have coupon code')}&nbsp;<span>{t('ClickHere')}</span></div>
                }
                {
                    this.props.hasProducts ?
                    <>
                        <div onClick={this.handleGoToPaymentClick} disabled={this.state.isCouponModalOpen} style={{ backgroundColor: mainColor }}>{shop.withoutPrices?t('continue'):t('Go to payment')}</div>
                        <div disabled={this.state.isCouponModalOpen} style={{ margin: 0}}>{t('The delivery cost will be determined after entering the address.')}</div>
                    </>
                    :
                    ''
                }
                </div>
            </div>
        )
    }
}

export default withTranslation()(PaymentButtons);
