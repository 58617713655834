import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Basket from './Basket';
import HistItem from './HistItem';
import Empty from "../Empty";
import { v4 as uuidv4 } from 'uuid';
import Spinner from 'Components/Spinner';
import { isMobile } from 'react-device-detect';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class StoredHistory extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             interval: null,
             times: null,
             loading: true,
             isMobile: window.innerWidth <= 850,
        }
    }

    handleClick = async () => {
        const { getOrderHistoryResp, setOrdersHistory } = this.props.myShopApiStore;

        !this.state.loading && this.setState({loading: true})
        const resp = await getOrderHistoryResp()
        this.setState({loading: false})

        if(resp && resp.errorCode === '0'){
            setOrdersHistory(resp.data.orders);
        }
    }

    handleScroll = async e => {
        const { scrollHeight, scrollTop, clientHeight } = e.target;
        const { getOrderHistoryResp, setOrdersHistory } = this.props.myShopApiStore;
        // console.log(e)
        // console.log('scrollHeight:', e.target.scrollHeight);
        // console.log('scrollTop:', Math.floor(e.target.scrollTop));
        // console.log('clientHeight:', e.target.clientHeight);
        
        if(scrollHeight - Math.floor(scrollTop) === clientHeight ||
                scrollHeight - Math.ceil(scrollTop) === clientHeight){
            const histResp = await getOrderHistoryResp()
            if(histResp && histResp.errorCode === '0' && histResp.data?.orders?.length){
                setOrdersHistory(histResp.data.orders);
            }
        }
    }

    updateIsMobile = () => {
        this.setState({ isMobile: window.innerWidth <= 850 });
    }

    componentWillUnmount() {
        // this.state.interval && clearInterval(this.state.interval);
        window.addEventListener('resize', this.updateIsMobile);

    }

    componentDidMount = async () => {
        const { ordersHistory, setOrdersHistory, getOrderHistoryResp, resetOrdersHistory, resetHistoryPage } = this.props.myShopApiStore;
        window.addEventListener('resize', this.updateIsMobile);

        resetOrdersHistory();
        resetHistoryPage();

        if(ordersHistory && !ordersHistory.length){
            const resp = await getOrderHistoryResp()
            if(resp && resp.errorCode === '0'){
                setOrdersHistory(resp.data.orders);
            }
        }   
    }
    
    getShippingStatus = (orderId) => {
        let shippingStatus = null;
        this.state.times && this.state.times.forEach(time => {
            if(orderId === time.orderId){
                shippingStatus = {...time};
            }
        })
        return shippingStatus;
    }

    render() {
        const { ordersHistory, resetOrdersHistory, setHistoryPage } = this.props.myShopApiStore;
        
        // console.log("ordersHistory::>>--------->",ordersHistory)
        return (
            <div className={style.container}>
                {
                    ordersHistory.length
                    ?
                        null
                    :
                        <>
                            <div className={style.Spinner}>
                                <Spinner loader='BeatLoader' loading={this.state.loading}
                                        color={this.props.myShopApiStore.mainColor}
                                        />
                                {this.state.loading ? null : <Empty hideBasket={this.props.hideBasket}/>}
                            </div>
                        </>
                }
                {/* <button onClick={this.handleClick}>FETCH DATA</button> */}
                {/* <button onClick={() => {resetOrdersHistory(); setHistoryPage(0)}}>RESET DATA</button> */}
                <div id="" className={style.TopDiv}>
                    <div className={style.ESpace}></div>
                    <div className={style.HistList} onScroll={ordersHistory.length ? this.handleScroll : null}>
                        {
                            (ordersHistory?.length && !this.state.isMobile) || 
                                (ordersHistory?.length && this.state.isMobile && !this.props.basketVisible)
                            ?
                                ordersHistory.map((order, idx) => 
                                    <React.Fragment key={uuidv4()}> 
                                        <HistItem order={order}
                                            isFirstOrder={!idx}
                                            // getShippingStatus={this.getShippingStatus}
                                            showBasket={this.props.showBasket}
                                            basketVisible={this.props.basketVisible}
                                        />
                                    </React.Fragment>)
                            :
                                null
                        }
                    </div>
                    {
                        (!this.state.isMobile && ordersHistory?.length)
                            || (this.state.isMobile && this.props.basketVisible)
                        ? 
                            <div className={this.state.isMobile ? style.mobileContBasket : style.contBasket}>
                                <div className={style.Basket}>
                                    <Basket />
                                </div>
                            </div>
                        :
                            ''
                    }
                </div>
            </div>
        )
    }
}

export default StoredHistory;
