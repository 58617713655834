import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import MenuItem from './MenuItem';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class SideMenu extends React.Component {
    render() {
        const { categories, mainColor, shop } = this.props.myShopApiStore;
        const { languageMap, i18nextLng } = this.props.ifaceMngr;

        if(!categories || categories.length === 1){
            return null;
        }

        const brdRadius =  getComputedStyle(document.querySelector(':root')).getPropertyValue('--brdRadius');
        const borderRadius = languageMap[i18nextLng].dir === 'ltr' ? `0 ${brdRadius} ${brdRadius} 0` : `${brdRadius} 0 0 ${brdRadius}`;


        return (
            <div className={style.Container} style={{backgroundColor: mainColor, borderRadius }}>
                <div className={style.TopDiv}>
                    {
                        categories.map((item, index) => {
                            this.props.myShopApiStore.setMaxSideMenuIdx(index);
                            return (
                                <MenuItem item={item} index={index} key={`p_${index}`}/>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default SideMenu;