import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import ValidSMSModal from '../../ValidSMSModal';
import ThanksModal from "../../ThanksModal";
import ThanksCouponModal from "../../ThanksCouponModal";
import TermsModal from "../TermsModal";
import APILoader from "../../APILoader";
import MakeOrderErrorModal from "../MakeOrderErrorModal";
import CardNumberModal from "../CardNumberModal";
import ReactPixel from 'react-facebook-pixel';

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class SimplePayOpForm extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
            errors: [],
            // name: this.props.myShopApiStore.userParams.name,
            // phone: this.props.myShopApiStore.userParams.phone,
            // email: this.props.myShopApiStore.userParams.email,
            isCheckedTerms: this.props.myShopApiStore.userToken ? true : false,
            smsModalIsOpen: false,
            thanksModalIsOpen: false,
            termsModalIsOpen: false,
            apiLoaderIsOpen: false,
            makeOrderErrorModalIsOpen: false,
            makeOrderErrorModalErrorCode: '',
            makeOrderErrorModalErrorMessage: '',
            cardNumberModalIsOpen: false
        }
    }
    
    handleChange = e => {
        const { setUserParams } = this.props.myShopApiStore; 
        const { name, value, checked } = e.target;

        if(name === 'isCheckedTerms'){
            this.setState({[name]: checked})
        }
        else{
            setUserParams(name, e.target.value)
        }

        let errors = this.state.errors;
        const eIndex = errors.indexOf(name);
        if (eIndex > -1) {
            errors.splice(eIndex, 1);
            this.setState({
                errors: errors,
            });
        }
    }

    // componentDidMount = async () => {
    //     this.setState({
    //         errors: []
    //     });
    // }

    errorConditions = () => {
        const { userParams } = this.props.myShopApiStore;

        let errors = [];
        if(userParams.name.trim() === ''){
            errors.push('name')
        }
        if(userParams.phone.trim() === '' || !/^\d+$/.test(userParams.phone) || userParams.phone.length !== 10){
            errors.push('phone')
        }
        if(userParams.email.trim() !== '' && !this.validateEmail(userParams.email.trim())){
            errors.push('email')
        }
        if(!this.state.isCheckedTerms){
            errors.push('isCheckedTerms')
        }

        return errors;
    }

    preLoadApi = async () => {
        const { createSessionResp, makePreorderResp, creditUniqueId, shop} = this.props.myShopApiStore;

        const resp = await makePreorderResp();

        //console.log('p1');
        //console.log(resp);

        if(resp.data.errorCode !== '0'){
            this.setMakeOrderErrorModalParams(resp.data.errorCode, resp.data.errorMessage);
            this.openMakeOrderErrorModal();
            return false;
        }
        else{
            if(typeof (shop.externalTypeCodename) != 'undefined' && shop.externalTypeCodename === 'comax') {
                //
            } else {
                await createSessionResp(creditUniqueId);
            }
            return true;
        }
    }

    handleCreditClick = async () => {
        const { setOrderData, registerUser, shop, calcTotalCartPrice, setCreditUniqueId} = this.props.myShopApiStore;
        
        if(this.state.apiLoaderIsOpen){ //cancel multiple (in a row) clicks on send button!
            return null;
        }

        const cartPrice = calcTotalCartPrice();
        if(cartPrice > 0) {
            const errors = this.errorConditions();
            if(!errors.length){
                if(process.env.REACT_APP_FACEBOOK_PIXEL != '') ReactPixel.track('AddPaymentInfo');
    
                this.openAPILoader()
                setOrderData("isCredit", true)
                // const regResp = await registerUser();
                const regResp = await registerUser();
                this.closeAPILoader();
    
                if(regResp.data){
                    const preorder = await this.preLoadApi();
                    if(preorder) this.props.nextStep()
                }
                else{
                    this.openSmsModal()
                }
    
            }
            else{
                this.setState({
                    errors
                })
            }
        } else {
            setCreditUniqueId('');
            return await this.handleStoreClick(true);
        }
    }

    closeMakeOrderErrorModal = () => {
        this.setState({makeOrderErrorModalIsOpen: false})
    }

    openMakeOrderErrorModal = () => {
        this.setState({makeOrderErrorModalIsOpen: true})
    }

    setMakeOrderErrorModalParams = (makeOrderErrorModalErrorCode, makeOrderErrorModalErrorMessage) => {
        this.setState({
            makeOrderErrorModalErrorCode: makeOrderErrorModalErrorCode,
            makeOrderErrorModalErrorMessage: makeOrderErrorModalErrorMessage
        })
    }

    handleStoreClick = async (fakeCredit = false) => {
        const { setOrderData, registerUser, setUserParams, orderData,
                userParams, makeOrderResp, userToken} = this.props.myShopApiStore;
        
        const errors = this.errorConditions();

        if(!errors.length){
            if(!fakeCredit) setOrderData("isCredit", false);

            this.openAPILoader()
            const regResp = await registerUser();
            this.closeAPILoader();

            if(regResp.data){
                this.openAPILoader();
                const orderResp = await makeOrderResp();
                this.closeAPILoader();

                if(orderResp.data.errorCode !== '0'){
                    this.setMakeOrderErrorModalParams(orderResp.data.errorCode, orderResp.data.errorMessage);
                    this.openMakeOrderErrorModal();
                }
                else{
                    if(process.env.REACT_APP_FACEBOOK_PIXEL != '') ReactPixel.track('Purchase');

                    this.openThanksModal();
                }
            } 
            else{
                this.openSmsModal();
            }
        }
        else{
            this.setState({
                errors
            })
        }
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    openAPILoader = () => {
        this.setState({apiLoaderIsOpen: true})
    }

    closeAPILoader = () => {
        this.setState({apiLoaderIsOpen: false})
    }

    openSmsModal = () => {
        this.setState({smsModalIsOpen: true})
    }

    closeSmsModal = async (isWrongSMSCode=true) => {
        this.setState({smsModalIsOpen: false})
        if(isWrongSMSCode) return;
        const { userToken, makeOrderResp, orderData } = this.props.myShopApiStore;
        if(userToken && !orderData.isCredit){//makes order for new user
            this.openAPILoader();
            const resp = await makeOrderResp();
            this.closeAPILoader();

            if(resp.data.errorCode !== '0'){
                this.setMakeOrderErrorModalParams(resp.data.errorCode, resp.data.errorMessage);
                this.openMakeOrderErrorModal();
            }
            else{
                if(process.env.REACT_APP_FACEBOOK_PIXEL != '') ReactPixel.track('Purchase');
                
                this.openThanksModal();
            }
        }
        else if(userToken && orderData.isCredit){//makes next-step for new user
            this.openAPILoader()
            await this.preLoadApi();
            this.props.nextStep()
            this.closeAPILoader();
        }
    }

    openThanksModal = () => {
        this.setState({thanksModalIsOpen: true})
    }
    closeThanksModal = () => {
        this.setState({thanksModalIsOpen: false})
    }

    openCardNumberModal = () => {
        this.setState({cardNumberModalIsOpen: true})
    }

    closeCardNumberModal = () => {
        this.setState({cardNumberModalIsOpen: false})
    }

    openTermsModal = () => {
        this.setState({termsModalIsOpen: true})
    }

    closeTermsModal = () => {
        this.setState({termsModalIsOpen: false})
    }

    render() {
        if(this.props.currentStep !== 2){
            return null;
        } 
        const { orderData, userParams, mainColor,
                shop, hasPostCoupon, setOtherPaymentType, my_env } = this.props.myShopApiStore;
        const { t } = this.props;
        const { paymentTypes } = shop;
        const canPayByCredit = paymentTypes?.some(pt => pt === "credit");
        const canPayByCash = my_env.url.indexOf("scooty") < 0 && paymentTypes?.some(pt => pt === "cash");
        const canPayByCibus = paymentTypes?.some(pt => pt === "cibus");
        const canPayByTenbis = paymentTypes?.some(pt => pt === "tenbis");
        const canPayByGoodi = paymentTypes?.some(pt => pt === "goodi");
        const canPayByDelay = paymentTypes?.some(pt => pt === "delay");
        const canPayByOther = paymentTypes?.some(pt => pt === "other");

        return (
            <div className={style.Container}>
                <div className={style.TitleText}>
                    {t('We will be happy to see you')}
                        <br/>
                    {t('If we need to contact you, please fill in your details here')}
                </div>
                <div className={style.InputFields}>
                    <form>
                        <div className={style.Inputs}>
                            <input type="text" name="name" placeholder={t('full name')} value={userParams.name} onChange={this.handleChange} style={{border: this.state.errors.includes('name') ? '1px solid #FF0000' : 'none'}}/>
                            <input type="tel" name="phone" placeholder={t('mobile phone')} value={userParams.phone} onChange={this.handleChange}  style={{border: this.state.errors.includes('phone') ? '1px solid #FF0000' : 'none'}}/>
                            <input type="email" name="email" placeholder={t('e-mail')} value={userParams.email} onChange={this.handleChange}  style={{border: this.state.errors.includes('email') ? '1px solid #FF0000' : 'none'}}/>
                            <div className={style.CheckTerm} >
                                <input type="checkbox" name="isCheckedTerms" checked={this.state.isCheckedTerms} onChange={this.handleChange}/>
                                <span style={{color: this.state.errors.includes('isCheckedTerms') ? '#FF0000' : 'rgb(255,255,255)'}}>
                                    {t('I am approve that I have read') + ' '}
                                    <span className={style.TextTermBtn} onClick={this.openTermsModal} style={{color: this.state.errors.includes('isCheckedTerms') ? '#FF0000' : 'rgb(255,255,255)'}}>{t('Conditions')}</span>
                                    {" " + t('I agree with all')}
                                </span>
                            </div>
                        </div>
                        <div className={style.Btns}>
                        {
                            shop.isBusiness
                            ?
                                <button type="button" onClick={() => this.handleStoreClick(false)} style={{backgroundColor: mainColor}}>{t('send')}</button>
                            :   
                                <>
                                    {canPayByCredit ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => this.handleCreditClick(false)}>{t('credit card')}</button> : null}
                                    {canPayByCash ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => this.handleStoreClick(false)}>{t('cash in store')}</button> : null}
                                    {canPayByCibus ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => {setOtherPaymentType('cibus'); this.openCardNumberModal()}}>{t('cibus')}</button> : null}
                                    {canPayByTenbis ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => {setOtherPaymentType('tenbis'); this.openCardNumberModal()}}>{t('tenbis')}</button> : null}
                                    {canPayByGoodi ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => {setOtherPaymentType('goodi'); this.openCardNumberModal()}}>{t('goodi')}</button> : null}
                                    {canPayByDelay ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => {setOtherPaymentType('delay'); this.handleStoreClick(false)}}>{t('delay')}</button> : null}
                                    {canPayByOther ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => {setOtherPaymentType('other'); this.handleStoreClick(false)}}>{t('other')}</button> : null}
                                </>
                        }
                            <button type="button" onClick={this.props.prevStep} id={style.BackBtn}>{t('back')}</button>
                        </div>
                    </form>
                </div>
                <div className={style.Modals}>
                    <CardNumberModal closeModal={this.closeCardNumberModal}
                                    modalIsOpen={this.state.cardNumberModalIsOpen}
                                    handleSendClick={() => this.handleStoreClick(false)}
                    />
                    <TermsModal closeModal={this.closeTermsModal} modalIsOpen={this.state.termsModalIsOpen} />
                    <ValidSMSModal closeModal={this.closeSmsModal} modalIsOpen={this.state.smsModalIsOpen} />
                    {
                        hasPostCoupon
                        ?
                            <ThanksCouponModal closeModal={this.closeThanksModal} modalIsOpen={this.state.thanksModalIsOpen} />
                        :
                            <ThanksModal closeModal={this.closeThanksModal} modalIsOpen={this.state.thanksModalIsOpen} />
                    }
                    <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
                    <MakeOrderErrorModal 
                        modalGoBack={true}
                        closeModal={this.closeMakeOrderErrorModal} 
                        modalIsOpen={this.state.makeOrderErrorModalIsOpen} 
                        makeOrderErrorModalErrorCode={this.state.makeOrderErrorModalErrorCode} 
                        makeOrderErrorModalErrorMessage={this.state.makeOrderErrorModalErrorMessage}
                        openBasket={this.props.openBasket}
                    />
                </div>
            </div>
        )
    }
}

export default withTranslation()(SimplePayOpForm);
