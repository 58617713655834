import { observable, action } from "mobx";

class HelperStore {
    @observable init = false;
    @observable contextMenuVisible = false;
    @observable contextMenuParams = {};
    @observable groupUserContextMenuVisible = false;
    @observable groupUserContextMenuParams = {};
    @observable userSettingsVisible = false;
    @observable selectedGroupId = null;
    @observable selectedEmptyGroupId = null;
    @observable selectedTopicId = null;
    @observable selectedReadMessageId = null;
    @observable selectedMenuMessageId = null;
    @observable selectedReplyMessageId = null;
    @observable selectedMenuMessageParams = null;
    @observable selectedMultiMessageIds = [];
    @observable selectedPublicGroup = null;
    @observable replyMessage = false;
    @observable selectedAllTopics = true;
    @observable selectedEditTopicId = null;
    @observable groupInfoVisible = false;
    @observable extraGroupUsersVisible = false;
    @observable groupEditChannelVisible = false;
    @observable groupEditChannelUsersVisible = false;
    @observable groupInviteVisible = false;
    @observable chatListFilter = '';
    @observable groupMessagesLoading = false;
    @observable popup = null;
    @observable groupMessagesScroll = false;
    @observable groupMessagesSearchVisible = false;

    resetState = () => {
        this.hideContextMenu();
        this.hideGroupUserContextMenu();
        this.hideGroupInfo();
        this.hideGroupMessagesSearch();
        this.hideUserSettings();
        this.hideGroupEditChannel();
        this.hideGroupInvite();
        this.setSelectedGroupId(null);
        this.setSelectedEmptyGroupId(null);
        this.setSelectedTopicId(null);
        this.setSelectedReadMessageId(null);
        this.setSelectedMenuMessageId(null);
        this.setSelectedMultiMessageIds([]);
        this.setChatListFilter('');
        this.setPopup(null);
        this.setReplyMessage(false);
        this.setSelectedPublicGroup(null)
    }

    @action
    setInit = () => {
        this.resetState();
        this.init = true;
    }

    @action
    off = () => {
        this.init = false;
    }

    @action
    setGroupMessagesScroll = (value) => {
        this.groupMessagesScroll = value;
    }

    @action
    setSelectedGroupId = (id) => {
        if (id !== this.selectedGroupId)
        {
            this.setReplyMessage(false);
            this.setSelectedMenuMessageId(null);
            this.setSelectedMultiMessageIds([]);
            this.setGroupMessagesLoading(true);
            this.hideGroupInfo();
            this.hideGroupMessagesSearch();
            this.hideGroupEditChannel();
            this.hideGroupInvite();
            this.selectedGroupId = id;
        }
    }

    @action
    setSelectedEmptyGroupId = (id) => {
        this.selectedEmptyGroupId = id;
    }

    @action
    setGroupMessagesLoading = (value) => {
        this.groupMessagesLoading = value;
    }

    @action
    setSelectedTopicId = (id) => {
        this.setReplyMessage(false);
        this.setSelectedMenuMessageId(null);
        this.setSelectedMultiMessageIds([]);
        this.selectedTopicId = id;
        this.setGroupMessagesScroll(true);
    }

    @action
    setSelectedReadMessageId = (id) => {
        this.selectedReadMessageId = id;
    }

    @action
    setSelectedMenuMessageId = (id) => {
        this.selectedMenuMessageId = id;
    }

    @action
    setSelectedReplyMessageId = (id) => {
        this.selectedReplyMessageId = id;
    }

    @action
    setSelectedMultiMessageIds = (ids) => {
        this.selectedMultiMessageIds = ids;
    }

    @action
    toggleSelectedMultiMessageId = (id) => {
        if (this.selectedMultiMessageIds.indexOf(id) >= 0) {
            this.selectedMultiMessageIds.splice(this.selectedMultiMessageIds.indexOf(id), 1);
        } else {
            this.selectedMultiMessageIds.push(id);
        }
    }

    @action
    setSelectedMenuMessageParams = (params) => {
        this.selectedMenuMessageParams = params;
    }

    @action
    setSelectedAllTopics = (value) => {
        this.setSelectedMultiMessageIds([]);
        this.selectedAllTopics = value;
    }

    @action
    setSelectedEditTopicId = (id) => {
        this.selectedEditTopicId = id;
    }

    @action
    showContextMenu = (params) => {
        this.setContextMenuParams(params);

        if(!this.contextMenuVisible) setTimeout(() => {
            this.contextMenuVisible = true;
        }, 10);
    }

    @action
    hideContextMenu = () => {
        if(this.contextMenuVisible) setTimeout(() => {
            this.contextMenuVisible = false;
        }, 10);
    }

    @action
    setContextMenuParams = (params) => {
        this.contextMenuParams = params;
    }

    @action
    showGroupUserContextMenu = (params) => {
        this.setGroupUserContextMenuParams(params);

        if(!this.groupUserContextMenuVisible) setTimeout(() => {
            this.groupUserContextMenuVisible = true;
        }, 10);
    }

    @action
    hideGroupUserContextMenu = () => {
        if(this.groupUserContextMenuVisible) setTimeout(() => {
            this.groupUserContextMenuVisible = false;
        }, 10);
    }

    @action
    setGroupUserContextMenuParams = (params) => {
        this.groupUserContextMenuParams = params;
    }

    @action
    showUserSettings = () => {
        this.userSettingsVisible = true;
    }

    @action
    hideUserSettings = () => {
        this.userSettingsVisible = false;
    }

    fireResize = () => {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 10);
    }

    @action
    toggleGroupInfo = () => {
        this.hideGroupMessagesSearch();
        this.hideGroupEditChannel();
        this.hideGroupInvite();
        this.groupInfoVisible = !this.groupInfoVisible;
        this.fireResize();
    }

    @action
    toggleGroupMessagesSearch = () => {
        this.hideGroupInfo();
        this.hideGroupEditChannel();
        this.hideGroupInvite();
        this.groupMessagesSearchVisible = !this.groupMessagesSearchVisible;
        this.fireResize();
    }

    @action
    hideGroupInfo = () => {
        this.groupInfoVisible = false;
        this.fireResize();
    }

    @action
    hideGroupMessagesSearch = () => {
        this.groupMessagesSearchVisible = false;
        this.fireResize();
    }

    @action
    showExtraGroupUsers = () => {
        this.extraGroupUsersVisible = true;
    }

    @action
    hideExtraGroupUsers = () => {
        this.extraGroupUsersVisible = false;
    }

    @action
    showGroupEditChannel = () => {
        this.groupEditChannelVisible = true;
    }

    @action
    hideGroupEditChannel = () => {
        this.groupEditChannelVisible = false;
    }

    @action
    showGroupEditChannelUsers = () => {
        this.groupEditChannelUsersVisible = true;
    }

    @action
    hideGroupEditChannelUsers = () => {
        this.groupEditChannelUsersVisible = false;
    }

    @action
    showGroupInvite = () => {
        this.groupInviteVisible = true;
    }

    @action
    hideGroupInvite  = () => {
        this.groupInviteVisible = false;
    }

    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    groupLastTimeFormat = (timestamp) => {
        const message_date = new Date(timestamp * 1000);
        const today = new Date();
        const isToday = message_date.getDate() === today.getDate() &&
            message_date.getMonth() === today.getMonth() &&
            message_date.getFullYear() === today.getFullYear();

        let result = '';

        if(!isToday) {
            result = this.format_dmy(timestamp);
        } else {
            result = this.format_hi(timestamp);
        }

        return result;
    }

    readTimeFormat = (timestamp) => {
        const message_date = new Date(timestamp * 1000);
        const today = new Date();
        const isToday = message_date.getDate() === today.getDate() &&
            message_date.getMonth() === today.getMonth() &&
            message_date.getFullYear() === today.getFullYear();

        let result = '';

        if(!isToday) {
            result = this.format_MONdy(timestamp);
        } else {
            result = this.format_hi(timestamp);
        }

        return result;
    }

    format_dmy = (timestamp) => {
        const date = new Date(timestamp * 1000);

        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        const result = dd + '/' + mm + '/' + yyyy;

        return result;
    }

    format_MONdy = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const today = new Date();

        const isToday = date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();

        let m = date.getMonth();
        let mon = this.monthNames[m];

        let d = date.getDate();
        let yyyy = date.getFullYear();

        const result = isToday ? 'Today' : (mon + ' ' + d + ', ' + yyyy);

        return result;
    }

    format_hi = (timestamp) => {
        const date = new Date(timestamp * 1000);

        let hh = date.getHours();
        let ii = date.getMinutes();
        if (hh < 10) {
            hh = '0' + hh;
        }
        if (ii < 10) {
            ii = '0' + ii;
        }
        const result = hh + ':' + ii;

        return result;
    }

    @action
    setChatListFilter = (value) => {
        this.chatListFilter = value;
    }

    @action
    setPopup = (value) => {
        this.popup = value;
    }

    @action
    setReplyMessage = (value) => {
        this.replyMessage = value;
    }

    @action
    setSelectedPublicGroup = (value) => {
        this.selectedPublicGroup = value;
    }
}

export default HelperStore