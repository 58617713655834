import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.css';
import Modal from 'react-modal';
import sad from '../../../../../../assets/sad.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function MissingProductsModal(props) {
        const modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%,-50%)',
                padding: 0,
                borderRadius: '20px',
                width: '50%',

            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.75)",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10,

            },
        }
        const { t } = useTranslation();
        const { modalIsOpen, closeModal, mainColor } = props;
        Modal.setAppElement('body');
    return(
        <Modal onRequestClose={closeModal}
                isOpen={modalIsOpen}
                style={modalStyle}   
                contentLabel={t('Products in the basket are not available')}>

            <div className={style.ModalContainer}>
                <div className={style.CloseBtn}>
                    <span onClick={closeModal}>✕</span>
                </div>
                <div className={style.Body}>
                    <LazyLoadImage effect='blur' src={sad} width='80px'/>
                    <div className={style.ModalTitle}>
                        <span>{t('Sorry')}</span>
                    </div>
                    <div className={style.RowText}>
                        <span>{t('Some of the products in the basket are not available and removed')}</span>
                    </div>
                    <button type="text" onClick={closeModal} style={{color: mainColor, border: `1px solid ${mainColor}`}} >
                        {t('Update basket')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}
   
export default MissingProductsModal