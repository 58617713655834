import "react-hot-loader/patch"
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './i18nextConf';

//def block

// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

//def block end

//from groupee:
// import { AppContainer } from 'react-hot-loader';
//notification fix for 'react-hot-loader':
import { setConfig } from 'react-hot-loader'
setConfig({
  showReactDomPatchNotification: false,
  integratedResolver: false
})

// function render(Component) {
//     const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate;
//     renderMethod(
//         <AppContainer>
//             <Component />
//         </AppContainer>,
//         document.getElementById('root')
//     )
// }

// function render(Component, id) {
//   const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate;
//   // var div2Render = React.createElement(Component == App ?
//   // <AppContainer>
//   //   <Component />
//   // </AppContainer> :
//   //  <Component />)
//   renderMethod(
//     React.createElement(Component == App ?
//       <AppContainer>
//         <Component />
//       </AppContainer> :
//        <Component />),
//       document.getElementById(!!id ? 'root' : id )
//   )
// }
function render(Component) {
    const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate;
    renderMethod(
            <Component />,
        document.getElementById('root')
    )
}
render(App);

if (module.hot) {
	module.hot.accept('./App.js', () => {
		const NewApp = require('./App.js');
		render(NewApp);
	});
}