import React from "react";
import style from "./style.module.css";
import { useTranslation } from "react-i18next"
//import { observer, inject } from 'mobx-react';

//@observer
function CategoryTime (props) {
    const { category, mainColor } = props;
    const { t } = useTranslation();
    //const { mainColor } = this.props.myShopApiStore;
    return (
        <div className={style.Container} style={{ backgroundColor: mainColor}} >
            <div className={style.TopDiv} >
                {t('CategoryProductsAvailableFromTo', {category})}
            </div>
        </div>
    )
}

export default CategoryTime