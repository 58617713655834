import React from "react";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next"; 
import style from "./style.module.css";

const SubForm1 = ({
  currentStep,
  handleChange,
  shopProductOptions,
  selectedProductOption,
  optionsDescription,
  optionsPaid,
  withoutPrices,
  _next
}) => {
  const { t } = useTranslation();
 
  const curr = t('currency');
  

  return (
    <div className={style.Container}>
      <div className={style.OptionsDescription}>{optionsDescription}</div>
      <div className={style.ItemOptionList}>{shopProductOptions.map((po, idx) => {
          return (
            <div key={idx} className={style.ItemOption}>
              <div className={style.WrapBtnAndName}>
                <input
                  className={style.ItemOptionRadioBtn}
                  type="radio"
                  value={po.id}
                  name="selectedProductOption"
                  checked={selectedProductOption.id == po.id}
                  onChange={(e)=> handleChange(e)}
                />
                <label className={style.ItemOptionName}>{po.productOption.name}</label>
              </div>
              <span className={style.ItemOptionPrice}>
                {!withoutPrices && ((!po.price || optionsPaid === 0) ? (
                  t('free')
                ) : (
                  <NumberFormat
                    value={po.price}
                    prefix={curr}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                  />
                ))}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubForm1;
