// import AccountKitStore from "./accountKit";
// import GroupeeApiStore from "./groupeeApi";
import MyShopApiStore from "./myShopApi";
import HelperStore from "./helper";
import StorageMngr from "./StorageMngr";
import IfaceMngr from "./IfaceMngr";
// export const accountKitStore = new AccountKitStore();
// export const groupeeApiStore = new GroupeeApiStore();
export const myShopApiStore = new MyShopApiStore();
export const helperStore = new HelperStore();
export const storageMngr = new StorageMngr();
export const ifaceMngr = new IfaceMngr();