import black from './black.png';
import blue from './blue.png';
import green from './green.png';
import red from './red.png' ;
import yellow from './yellow.png';

const bgWelcomeMap = {
    '#00B1E1': blue, // 1	כחול	00B1E1
    '#1BB449': green, // 2	ירוק	1BB449
    '#EB0028': red, // 3	אדום	EB0028
    '#F1A103': yellow, // 4	כתום	F1A103
    '#292929': black // 5	אפור	292929
}
export default bgWelcomeMap;