import React from "react";
//import { toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import CartItem from "./CartItem";
import NumberFormat from "react-number-format";
import ItemUpdateForm from "./ItemUpdateForm/index";
import PackItemUpdateForm from "./PackItemUpdateForm";
import EditCartPackForm from "./EditCartPackForm";
import PaymentButtons from "./PaymentButtons";
import blackBasket from 'assets/basket/blackBasket.png'
import redBasket from 'assets/basket/redBasket.png'
import blueBasket from 'assets/basket/blueBasket.png'
import greenBasket from 'assets/basket/greenBasket.png'
import yellowBasket from 'assets/basket/yellowBasket.png'
import blackCart from 'assets/cart/blackCart.png'
import redCart from 'assets/cart/redCart.png'
import blueCart from 'assets/cart/blueCart.png'
import greenCart from 'assets/cart/greenCart.png'
import yellowCart from 'assets/cart/yellowCart.png'
import MissingProductsModal from "./MissingProductsModal";
import OrderTypeSwitch from "./../../Payment/ShippingOptionForm/OrderTypeSwitch";
//import ProductsAssociatedModal from "../../ProductsAssociatedModal";

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})

@observer
class Basket extends React.Component {

  constructor(props) {
    super(props)
    this.scrollUpRef = React.createRef();

    this.state = {
      isMobile: window.innerWidth <= 850,
      productsAssociated: null,
      productsAssociatedModalIsOpen: false,
      isBasketOpened: true, 
      isMissingProductsModalOpen: false,
      rtl: document.body.dir === 'rtl'
    }
  }

  updateIsMobile = () => {
    this.setState({ isMobile: window.innerWidth <= 850 });
  }

  componentDidMount = () => {
    this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})

    window.addEventListener('resize', this.updateIsMobile); 
  }

  componentDidUpdate() {
    this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'});
    this.state.rtl !== (document.body.dir === 'rtl') && this.setState({ rtl: document.body.dir === 'rtl'});

  }

  

  componentWillUnmount() {
    // console.log('Basket > componentWillUnmlount')
    window.removeEventListener('resize', this.updateIsMobile);

    this.props.basketIsOpen && this.props.closeBasket(); //mobile - goes back to menu (and not to basket)!
    
  }
  
  handleCheckboxChange = (event, id) => {
    const { thisCart, calcTotalCartPrice } = this.props.myShopApiStore;
    
    thisCart.cartItemList.forEach(cartItem => {
      if(cartItem.id === id){
        cartItem.isReadyToOrder = event.target.checked;
      }
    })

    calcTotalCartPrice();
  }

  getCartIcon = () => {
    const { mainColor } = this.props.myShopApiStore;

    const colorPallet = {
      '#EB0028': redCart,
      '#1BB449': greenCart,
      '#00B1E1': blueCart,
      '#F1A103': yellowCart,
    }
    
    return colorPallet[mainColor]??blackCart;
  }
  
  getBasketIcon = () => {
    const { mainColor } = this.props.myShopApiStore;
    switch(mainColor){
      case '#EB0028':
        return redBasket
      case '#1BB449':
        return greenBasket
      case '#00B1E1':
        return blueBasket
      case '#F1A103':
        return yellowBasket
      default:
        return blackBasket
    }
  }

  openMissingProductsModal = () => {
    this.setState({isMissingProductsModalOpen: true });
  }

  closeMissingProductsModal = () => {
    this.setState({isMissingProductsModalOpen: false});
  }

  changeOrderType = (toType) => {
        const { setOrderData, resetOrderData, shop, orderData, getItemList} = this.props.myShopApiStore;
        // this.setState({selectedTimeIdx: ''}); 
        if (toType === orderData.orderType) return;
        // resetOrderData(); 
        //setOrderData('isDelivery', toType === 'delivery'); 
        setOrderData('orderType', toType);
       
  }


  renderStepsBar = () => {
    const { stepNum, maxStepNum, setStepNum, cartItemUpdating } = this.props.myShopApiStore;
    return(
      <div>
        <div className={style.StepsBar}>
          {cartItemUpdating.steps.map(stepBar =>
            <React.Fragment key={stepBar.stepNum}>
                {stepBar.stepNum !== 1 && <>&nbsp;- -&nbsp;</>}
                <div className={style.StepBar} 
                    style={{
                            color: 
                                stepBar.stepNum <= maxStepNum && "#5c5c5c",
                            border:
                                stepBar.stepNum === stepNum ? "1px solid #5c5c5c" :"1px solid #dcdcdc",
                            cursor:
                                stepBar.stepNum <= maxStepNum ? "pointer" : "default",
                          }}
                    onClick={()=>{if(stepBar.stepNum <= maxStepNum) setStepNum(stepBar.stepNum)}}>
                    {stepBar.stepNum}
                </div>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  openProductsAssociatedModal = () => {
    this.setState({productsAssociatedModalIsOpen: true});

    if(!this.props.basketIsOpen) {
      this.props.fastToggleBasket();
    }
  }

  closeProductsAssociatedModal = () => {
    this.setState({productsAssociatedModalIsOpen: false});

    if(!this.state.isBasketOpened) {
      this.props.fastToggleBasket();
    }
  }

  checkForProductsAssociated = async (item, isAdded = false) => {
    const { addToCart, isPackItemUpdating,
      isCartItemUpdating } = this.props.myShopApiStore;
    // const item = itemAssociated ? itemAssociated : this.props.item;

    const unitTypes = item.unitTypes ? item.unitTypes : null;
    let isDuringOrder = isPackItemUpdating || isCartItemUpdating;

    if(!isDuringOrder || !isAdded){
      addToCart(item, unitTypes?.length ? unitTypes[0] : null)
      return;
    }
  }

  render() {
    const { cartPrice, cartPriceBeforeDiscount,
            isCartItemUpdating, isEditCartPackForm, isPackItemUpdating, cartItemUpdating, 
            mainColor, shop, thisCart, orderData, orderTypesOvertime } = this.props.myShopApiStore;
    const { t } = this.props;
    
    let itemsInCart = 0;
    thisCart?.cartItemList?.forEach(cartItem => {cartItem.isReadyToOrder && itemsInCart++})
    const curr = "₪";
    const isCartPackItem = cartItemUpdating && (cartItemUpdating.item.product.levels ? cartItemUpdating.item.product.levels.length : false);
    return (
      <div className={style.Container} style={this.state.rtl ? {marginLeft: '15px'}: {marginRight: '15px'}}>
        <MissingProductsModal closeModal={this.closeMissingProductsModal} modalIsOpen={this.state.isMissingProductsModalOpen} mainColor={mainColor}/>
        <div className={style.TopDiv} style={{height: isCartItemUpdating ? '100%' : '95%'}}>
          {
            !this.state.isMobile && 
              <div className={style.Summary} style={{ borderColor: this.props.myShopApiStore.mainColor }}>
                <div className={style.CartWrapper}>
                  <span
                    className={style.ItemsInCart}
                    style={{ backgroundColor: this.props.myShopApiStore.mainColor, left: this.state.rtl ? '-55%': '0' }}
                  >
                    {itemsInCart}
                  </span>
                  <img className={style.CartImg} style={{marginLeft: this.state.rtl ? '15px': '-15px'}} src={this.getCartIcon()}  alt="cart" />
                </div>
                {
                  !shop.isBusiness && <>
                    <div className={style.CartSum} style={{ color: this.props.myShopApiStore.mainColor }} >
                      { cartPriceBeforeDiscount && cartPriceBeforeDiscount !== cartPrice &&
                        <NumberFormat
                            value={cartPriceBeforeDiscount}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"text"}
                            style={{textDecoration: 'line-through', fontSize: '14px', color: 'black', margin: '4px'}}
                          />
                          
                      }   
                      <div className={style.CartSumVal} >
                        <span className={style.CartSumSh}>{curr}</span>
                        <NumberFormat
                          value={cartPrice}
                          // prefix={curr}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                        /> 
                      </div>
                    </div>
                    <div className={style.CartText} style={{ color: this.props.myShopApiStore.mainColor, marginLeft: '7px' }}>
                      { t('total') }
                      <br />
                      { t('forPayment') }
                    </div></>
                }
              </div>
          }
          { shop?.isPriceOrderType
            && !this.state.isMobile
            && !isPackItemUpdating 
            && !isCartItemUpdating?
            <OrderTypeSwitch  changeOrderType={this.changeOrderType} 
                              orderType={orderData?.orderType}
                              mainColor={mainColor}
                              shop={shop}   
                              thisCart={thisCart}
                              orderTypesOvertime={orderTypesOvertime}
          />:null}
          { (!isPackItemUpdating && isCartPackItem)?this.renderStepsBar():null
          }
          {isCartItemUpdating 
          ? (
            isCartPackItem ? (isEditCartPackForm ? <EditCartPackForm /> : <PackItemUpdateForm checkForProductsAssociated={this.checkForProductsAssociated}/>) : <ItemUpdateForm checkForProductsAssociated={this.checkForProductsAssociated}/>
          ) 
          : (
            <div className={style.CartItems}>
            {
              (thisCart?.cartItemList.length) 
              ? (
                  thisCart.cartItemList.map((cartItem, idx) => {
                    return (
                      <div key={`${cartItem.item.id}_${idx}`} ref={this.scrollUpRef}>
                        <CartItem cartItem={cartItem} index={idx} handleCheckboxChange={this.handleCheckboxChange} />
                      </div>
                    )
                  })
                )
              : (
                <div className={style.CartItemsEmpty} style={{color: mainColor, zIndex: 0}} onClick={this.state.isMobile ? this.props.closeBasket : null}>
                  <span>{ t('Your basket is empty') }</span>
                  <span>{ t('Start adding products to your basket short') }</span>
                  <img className={style.BasketImg} src={this.getBasketIcon()} alt="basket" />
                </div>
              )}
          </div>
          )}
          {/*<div className={style.CartButtons}>buttons</div>*/}
          <div className={style.PaymentButtons}>
            <PaymentButtons hasProducts={thisCart?.cartItemList.some(cartItem => cartItem.isReadyToOrder) && !isCartItemUpdating} openMissingProductsModal={this.openMissingProductsModal}/>
          </div>
        </div>
        
      </div>
      );
  }
}
export default withTranslation()(Basket)