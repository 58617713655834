import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import Modal from 'react-modal';
import happy from '../../../../../../../assets/happy.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class CouponSuccessModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                // width: '30%',
                width: '340px',
                // height: '250px'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }
    
    handleCodeChange = (e) => {
        const { name, value } = e.target;

        if(value.length && !value.match(/\D/)){
            this.setState({[name]: value.slice(-1)});
        }
    }

    handleClick = () => {
        this.props.closeModal();
    }
    
    render() {
        const { modalIsOpen, t } = this.props;
        const { getCoupon } = this.props.myShopApiStore;

        const coupon = getCoupon();
        let discount_value = coupon?.discount?.toFixed(1);
        let discount_type = '%';
        if(typeof coupon?.type != 'undefined') switch(coupon.type) {
            case 'percent':
                discount_value = coupon.discount.toFixed(1);
                discount_type = '%';
                break;
            case 'product':
                discount_value = '';
                discount_type = t('product');
                break;
            case 'money':
                discount_value = coupon.discount.toFixed(1);
                discount_type = '₪';
                break;
            default:
                discount_value = '';
                break;
        }

        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.handleClick}>
                <div className={style.ModalContainer}>
                    <div className={style.CloseBtn}>
                        <span onClick={this.handleClick}>✕</span>
                    </div>
                    <div className={style.Body}>
                        <LazyLoadImage effect='blur' src={happy} width='80px'/>
                        <div className={style.ModalTitle}>
                            <span>
                                {t('CouponSuccessfullyEntered', {sum: (modalIsOpen ? `${discount_value}${discount_type}` : '')})}
                            </span>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(CouponSuccessModal);