import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import Modal from 'react-modal';


@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class LoadErrorModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width: '365px',
                height: '180px',
                maxHeight: '300px'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 1
            }
        }
    }

    render() {
        const { modalIsOpen, closeModal, t } = this.props;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={closeModal}>
                <div className={style.Container}>
                    <div className={style.Title}>
                        <span>{t('error')}!</span>
                    </div>
                    <div className={style.Body}>
                        <span>{t('there is no connection')}</span>
                        <span>{t('try one more time')}</span>
                    </div>
                    <div className={style.Button} onClick={closeModal}>
                        <span>{t('back')}</span>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(LoadErrorModal);