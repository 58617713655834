// import '@babel/polyfill';
import React, { useEffect } from 'react';
import { Provider } from "mobx-react";
import Root from './Components/Root';
import * as store from "./Stores";
import { AsyncTrunk } from 'mobx-sync';
import 'react-bootstrap';
import './App.css';
//import { isMobile } from 'react-device-detect';
//import packageJson from "../package.json";
//import { getBuildDate } from "./utils/utils";
//import withClearCache from './ClearCache';
//import ReactPixel from 'react-facebook-pixel';

const trunk = new AsyncTrunk(store, { storage: localStorage });

trunk.init().then(() => {
    store.myShopApiStore.setInit();
    store.helperStore.setInit();
    store.storageMngr.setInit();
    store.ifaceMngr.setInit();
});

function App(props) {
        useEffect(() => {
            window.process = {
            ...window.process,
            };
            store.ifaceMngr.allPixelsInit();
        }, []);
        return(
                <Provider {...store}>
                    <Root/>
                </Provider>
        )
}

export default App;
