import React, { Suspense } from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import { withTranslation } from 'react-i18next';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class About extends React.Component {

    render() {
        const { t } = this.props;
        return (
        <div className={style.container}>
            <div className={style.Header}>
                { (this.props.ifaceMngr.activeTopMenu === "About") ? t('About') : '' }
                { (this.props.ifaceMngr.activeTopMenu === "Terms") ? t('Terms') : '' }
                { (this.props.ifaceMngr.activeTopMenu === "RetPolicy") ? (this.props.myShopApiStore.my_env.url.indexOf("falafelzikhron") >= 0 ? t('Franchise') : t('RetPolicy')) : '' }
                { (this.props.ifaceMngr.activeTopMenu === "PPolicy") ? (this.props.myShopApiStore.my_env.url.indexOf("falafelzikhron") >= 0 ? t('Contacts') : t('PPolicy')) : '' }
                { (this.props.ifaceMngr.activeTopMenu === "Accessibility") ? t('Accessibility') : '' }
            </div>
            <div id="about" className={style.About}>
                { (this.props.ifaceMngr.activeTopMenu === "About") ? this.props.myShopApiStore.getAbout() : '' }
                { (this.props.ifaceMngr.activeTopMenu === "Terms") ? this.props.myShopApiStore.getTerms() : '' }
                { (this.props.ifaceMngr.activeTopMenu === "RetPolicy") ? this.props.myShopApiStore.getRetPolicy() : '' }
                { (this.props.ifaceMngr.activeTopMenu === "PPolicy") ? this.props.myShopApiStore.getPPolicy() : '' }
                { (this.props.ifaceMngr.activeTopMenu === "Accessibility") ? this.props.myShopApiStore.getAccessibility() : '' }
            </div>
        </div>
        )
    }
}
export default withTranslation()(About);
