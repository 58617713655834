import React, { createRef } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import Modal from 'react-modal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ThanksCouponModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canvasRef: createRef(null)
        }

        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '6px',
                width: '100%',
                maxWidth: 'max-content',
                height: 'max-content',
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    handleClick = () => {
        this.props.closeModal();
        //this.props.myShopApiStore.clearReadyToOrderCartItems();
        this.props.ifaceMngr.setActiveTopMenu("ShopMenu");
    }
    
    handleDownloadCouponClick = () => {
        htmlToImage.toPng(document.getElementById('my-coupon'))
        .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = 'my-coupon.png';
            link.href = dataUrl;
            link.click();
        });
    }

    render() {
        const { modalIsOpen, t } = this.props;
        const { shop, postCouponData, shopsData, my_env, isSingleStore } = this.props.myShopApiStore;

        let discount_value = postCouponData?.discount?.toFixed(1);
        let discount_type = '%';
        if(typeof postCouponData.couponType != 'undefined') switch(postCouponData.couponType) {
            case 'percent':
                discount_value = postCouponData.discount.toFixed(1);
                discount_type = '%';
                break;
            case 'product':
                discount_value = '';
                discount_type = t('product');
                break;
            case 'money':
                discount_value = postCouponData.sum.toFixed(1);
                discount_type = '₪';
                break;
            default:
                discount_value = '';
        }

        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.handleClick}>
                <div className={style.ModalContainer} id="my-coupon">
                    <div className={style.Content}>
                        <LazyLoadImage effect='blur' src={shop.image && !isSingleStore ? shop.image : my_env.url + '/logo.png'} width='120px'/>
                        <div className={style.BodyContent}>
                            <p>{t('ThankYouForOrder', {shop})}</p>
                            <p>{t('We got you a discount', {discount_value, discount_type})}</p>
                            <p>{t('Code coupon is', {code: postCouponData?.code})}</p>
                            <p>{t('To download coupon')} <span onClick={this.handleDownloadCouponClick}>{t('ClickHere')}</span></p>
                        </div>
                        <div className={style.FootContent}>
                            <p>{t('Its important to know')}!</p>
                            <p>{t('The coupon was sent to your email.')} {t('The Coupon expires on',{date: postCouponData.dateToFormat})}</p>
                            <p>{t('The coupon is personal and non-transferable')}</p>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(ThanksCouponModal);