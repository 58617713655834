import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import Modal from "react-modal";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import Slider from 'react-slick';
import img1 from '../../../../../../../assets/bg/bg_4.jpg';
import img2 from '../../../../../../../assets/bg/bg_5.jpg';
import img3 from '../../../../../../../assets/bg/bg_6.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class CarouselModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
             imgIndex: 0,
        }
    }

    nextImg = () => {
        const { imageBig } = this.props.item.product;
        this.setState({imgIndex: (this.state.imgIndex + 1) % imageBig.length})
    }
    prevImg = () => {
        const { imageBig } = this.props.item.product;
        this.setState({imgIndex: this.state.imgIndex === 0 ? imageBig.length-1 : this.state.imgIndex-1})
    }
    
    render() {
        const { imageBig } = this.props.item.product;
        return (
            <Modal
                onRequestClose={this.props.closeModal}
                isOpen={this.props.modalIsOpen} 
                style={
                    {
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,.75)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1

                        },
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            padding: 0,
                            maxHeight: '100%',
                            maxWidth: '100%',
                            backgroundColor: 'unset',
                            border: 'unset',
                            height: '90%',
                            width: '70vw',
                        }
                    }
                }>
                <div className={style.Container} onClick={this.props.closeModal}>
                    <div className={style.cancleBtn} onClick={this.props.closeModal}>✕</div>
                    <div className={style.Slider}>
                        {
                            typeof imageBig !== 'string'
                            ?
                                <div className={style.RightArrow} onClick={this.prevImg}>❮</div>
                            :
                                null
                        }
                        <div className={style.Images}>
                            {
                                typeof imageBig !== 'string'
                                ?
                                    imageBig.map((img, idx) => idx === this.state.imgIndex ? <LazyLoadImage effect='blur' key={idx} src={img} height='auto' style={{ display: 'block', borderRadius: '10px'}} /> : null)
                                :
                                    <LazyLoadImage 
                                        effect='blur' src={imageBig} height='auto' 
                                        style={{ display: 'block', borderRadius: '10px'}}
                                    />
                            }
                        </div>
                        {
                            typeof imageBig !== 'string'
                            ?
                                <div className={style.LeftArrow} onClick={this.nextImg}>❯</div>
                            :
                                null
                        }
                        
                    </div>
                    <div className={style.Dots}>
                        {
                            typeof imageBig !== 'string'
                            ?
                                imageBig.map((_, idx) => <div className={style.Dot} key={idx} style={{cursor: 'pointer',backgroundColor: idx === this.state.imgIndex ? '#fff' : 'rgba(0,0,0,.5)'}}/>)
                            :
                                null
                        }
                    </div>
                </div>
            </Modal>
        )
    }
}

export default CarouselModal
