import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import ReactTooltip from 'react-tooltip';
import NumberFormat from "react-number-format";

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})

@observer
class PackItem extends React.Component {
    render(){
        const { packItem, handleAddPackItemClick } = this.props;
        const { mainColor, shop } = this.props.myShopApiStore;
        const { t } = this.props;
        return(
            <div className={style.Container} style={{border: packItem.isReadyToOrder ? `1px solid ${mainColor}` : "1px solid #e6e6e6",
                                                    backgroundColor: packItem.isReadyToOrder && "#F9F9F9"}}>
                <div className={style.RightElements}>
                    {/* <div className={style.Checked}>
                        <input type="checkbox" checked={packItem.isReadyToOrder} onChange={() => handleCheckboxChange(packItem.id)}/> 
                    </div> */}
                    <div className={style.ItemImage}>
                        <img
                            width="100px"
                            src={packItem.item.product ? packItem.item.product.image : ""}
                            alt={packItem.item.product ? packItem.item.product.description : ""}
                        />
                    </div>
                </div>
                <div className={style.MidElements}>
                    <div className={style.ItemDetails}>
                        <div className={style.ItemName} style={{color: mainColor}} title={packItem.item.product.name}>
                            {packItem.item.product.name}
                        </div>
                        <div className={style.ItemPrice}>
                            {
                            ( !shop.withoutPrices && this.props.productsPaid == 1)
                            ?
                                <NumberFormat
                                    value = {packItem.item.unitType ? packItem.item.unitType.price : packItem.item.unitTypes[0].price}
                                    prefix={"₪"}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType={"text"}
                                />
                            :   ""
                            }
                        </div>
                    </div>
                    <div className={style.ItemButtons}>
                        <div className={style.ItemUnitsBtn} style={{color: mainColor, border: `1px solid white`}}>
                            {packItem.item.defaultUnitType === 'kg' ? t('kg') : t('unit') }
                        </div>
                        <div 
                            className={style.ItemAddBtn}
                            onClick={() => handleAddPackItemClick(packItem, this.props.stepNum)}
                            style={{color: mainColor, border: `1px solid ${mainColor}`}}
                            >
                            
                            {!packItem.isReadyToOrder ? `${t('add')} +` : packItem.item.shopProductOptions.length ? t('update') : t('added')}
                        </div>
                    </div>
                </div>
                <div className={style.LeftElements}>
                    <div className={style.MoreInfoTooltip} style={{color: mainColor}}>
                        {packItem.item.product.description && 
                            <>
                            <div className={style.label} data-tip={packItem.item.product.description} data-for={`moreInfo_${packItem.id}`}>{t('More info')}</div>
                            <ReactTooltip 
                                className={style.Tooltip}
                                id={`moreInfo_${packItem.id}`}
                                multiline={true}
                                data-html={true}
                                insecure={true} 
                                type='light'
                                border={true}
                                effect='solid'
                                place="right" 
                            />
                                {/* <span>{packItem.item.product.description}</span> */}
                            {/* </ReactTooltip> */}
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(PackItem);
