import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import Item from './Item';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class Basket extends React.Component {
    
    handleAddToCartClick = () => {
        const { basketHistory, addToCart, closeCartItemForm} = this.props.myShopApiStore;

        if(basketHistory){
            basketHistory.products.forEach(product => {
                addToCart(product.shopProduct, product.unitType);
                closeCartItemForm();
            }); 
        }
    }

    render() {
        const { basketHistory, mainColor, resetOrdersHistory} = this.props.myShopApiStore;
        const { t } = this.props;
        // resetOrdersHistory();
        return (
            <div className={style.container}>
                <div id="" className={style.TopDiv}>
                    {
                        basketHistory
                        ?
                            <div className={style.OrderNumber} style={{color: mainColor}}>{`${t('_order number')} ${basketHistory.id}#`}</div>
                        :
                            ''
                    }
                    
                    <div className={style.List}>
                        {
                            basketHistory && basketHistory.products
                            ?
                                basketHistory.products.map((item, index) => {
                                    return(
                                        <div key={`basket_item_${index}`} style={{padding: '5px 0 5px 0', borderBottom: '2px solid rgba(0,0,0,.2)'}}>
                                            <Item item={item} index={index} key={`p_${index}_${item.id}`} />
                                        </div>
                                    )
                                })
                            :
                                null
                        }
                    </div>
                    {/* <div className={style.ButtonWrapper}>
                        <div className={style.button}
                            style={{border: `1px solid ${mainColor}`, color: mainColor}}
                            onClick={this.handleAddToCartClick}
                            >
                                העתק לסל
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default withTranslation()(Basket);