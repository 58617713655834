import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class UnitTypes extends React.Component {
       
    styleUnit = (unitType) => {
        const { mainColor } = this.props.myShopApiStore;
        const isSelected = this.props.selected === unitType.type;
        const styling = {
            backgroundColor: isSelected ? mainColor : "unset",
            color: isSelected ? "#FFFFFF" : "#000000",
        }

        return styling;
    }

    handleClick = (unitType) => {
        const { removeCartItem, thisCart, addToCart } = this.props.myShopApiStore;
        const { item } = this.props;
        const hasMult = item.product.shopToppings ? item.product.shopToppings.length === 0 : false
        let index = -1;
        for(let i=0; i<thisCart.cartItemList.length ; i++){
            if(thisCart.cartItemList[i].item.id === item.id){
                index = i;
                break;
            }
        }

        if(unitType.type !== this.props.selected && index !== -1){
            removeCartItem(index, hasMult);
            addToCart(item, unitType)
        }
        this.props.setUnitTypeSelected(unitType);

        //this.setState({unitSelected: unitType.type})
    }

    render() {
        const { unitTypes, t } = this.props;
        return (
            <div className={style.Container}>
                {
                    unitTypes.map((unitType, unitIdx) => {
                        return(
                            <div className={style.Unit} key={unitIdx} style={this.styleUnit(unitType)} onClick={() => this.handleClick(unitType)}>
                                { t(unitType.type) }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default withTranslation()(UnitTypes);
