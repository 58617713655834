import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";

const START_COUNT = 5;

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class CouponModal extends React.Component {
    constructor(props) {
        super(props)
        // this.isMountedRef = React.createRef();
        this.state = {
             isValidCode: true,
             errorCode:'',
             code: '',
             isButtonDisabled: false,
             count: START_COUNT,
             interval: null,
        }
    }

    componentWillUnmount() {
        this.props.precloseCouponModal();
    }

    handleChange = e => {
        this.setState({code: e.target.value});
    }

    timer = () => {
        this.setState({count: this.state.count - 1});
    }

    activateCoupon = async () => {
        const { shop, checkCouponResp } = this.props.myShopApiStore;
        if(this.state.code.trim() === ''){
            this.setState({isValidCode: false})
        }
        else if(shop.withCoupons){
            const interval = setInterval(this.timer, 1000);
            this.setState({isButtonDisabled: true, interval})
            
            setTimeout(() => {
                clearInterval(this.state.interval);
                this.setState({isButtonDisabled: false, count: START_COUNT, interval: null})
            }
            , START_COUNT * 1000)

            const resp = await checkCouponResp(this.state.code);
            const { errorCode } = resp;
            // console.log("ERROR_CODE::",errorCode)
            if(errorCode !== '0'){
                this.setState({isValidCode: false, errorCode})
            }
            else{
                const { addToCart, calcTotalCartPrice } = this.props.myShopApiStore;

                let couponSuccess = true;

                if(resp.data.product != null) {
                    addToCart(resp.data.product, resp.data.product.unitTypes[0], true);
                    couponSuccess = false;
                } else {
                    calcTotalCartPrice(); //calculate new total cart price (after discount)
                }

                clearInterval(this.state.interval);
                this.setState({isValidCode: false, errorCode:''})
                this.props.closeModal(couponSuccess);
            }
        }
    } 
    
    render() {
        const { mainColor } = this.props.myShopApiStore;
        const { t } = this.props;
        return (
            <div className={style.Container}>
                    <div className={style.CouponTitle}>{t('Enter your code coupon here:')}</div>
                    <div className={style.CouponInput}>
                        <input type="text" onChange={this.handleChange}/>
                    </div>
                    <div className={style.CouponError} style={{visibility: (this.state.isValidCode?'hidden':'visible')}}>
                        <span>{
                            this.state.errorCode === 'COUPON_WRONG_ORDER_TYPE' 
                            ? 
                                t('the code is not suite for selected order type')
                            :
                                t('the coupon code is not working')
                            }
                        </span>
                    </div>
                    <div className={style.CouponBtns}>
                        <button className={style.NoCoupon} onClick={() => this.props.closeModal(false)}>{t('I haven’t a coupon')}</button>
                        <button className={style.CouponOn} onClick={this.activateCoupon} style={{backgroundColor: this.state.isButtonDisabled ? 'rgb(255, 255, 255, 0.07)' : mainColor, border: this.state.isButtonDisabled ? '1px solid #a5a5a5' : 'unset'}} disabled={this.state.isButtonDisabled}>{t('Activate code coupon')}</button>
                    </div>
                    <div className={style.TimeBar}>
                        {this.state.isButtonDisabled ? <span>{this.state.count}</span> : ''}
                    </div>
          
            </div>
        )
    }
}

export default withTranslation()(CouponModal);
