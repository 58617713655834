import { useMediaQuery } from 'react-responsive';

export const useScreenType = () => {
    // const is3Cols = useMediaQuery({ minWidth: 1440 });
    const is3Cols = useMediaQuery({ minWidth: 851 });
    // const is2Cols = useMediaQuery({ minWidth: 1256 });
    const is2Cols = useMediaQuery({ minWidth: 851 });
    // const is1Cols = useMediaQuery({ minWidth: 800 });

    if(is3Cols){ //desktop
        return '3-cols';
    }
    if(is2Cols){ //tablet
        return '2-cols';
    }
    // if(is1Cols){ //small tablet
    //     return '1-cols';
    // }

    return 'fullscreen'; //phone
};