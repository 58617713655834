import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import Modal from "react-modal";
const buttonStyle = {
    alignSelf: 'center',
    justifySelf: 'center',
    marginLeft: '10px',
    color: 'var(--black)', 
    backgroundColor: 'var(--mainWhite)',
    border: 'solid var(--mainBg) 1.9px', 
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    cursor: 'pointer',
    fontSize: '24px',
    padding: '0px',
}

const itemButton = {
    ...buttonStyle,
}

function LanguageModal(props) {
    const { isOpen, closeModal, languageMap, setI18nextLng, setLng, i18nextLng } = props;
    const modalStyle = {
        content : {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%,-50%)',
            padding: 0,
            borderRadius: '20px',
            width: '50%',
            maxWidth: '300px',
            overflow: 'hidden',
            
        },
        overlay: {
            backgroundColor: "rgb(0, 0, 0, 0.75)",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
        }
    }

    const setLngAndClose = (lng) => {
        setI18nextLng(lng);
        closeModal();
        setLng(lng);
    }

    Modal.setAppElement('body');

    return (  
        <Modal style={ modalStyle } isOpen={isOpen} onRequestClose={closeModal} 
        ariaHideApp={false}
        >
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                {Object.keys(languageMap)?.map(item => (
                    <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%'}} key={item}>
                        <button onClick={() => setLngAndClose(item)}
                            style={itemButton}
                            
                            >{languageMap[item].label}
                        </button>
                        <input type="radio" name="language" value={item} checked={i18nextLng === item} onChange={() => setLngAndClose(item)} style={{ color: 'var(--mainColor)', cursor: 'pointer' }}/>
                    </div>
                ))}
            </div>
        </Modal>
    );
}

@inject((store) => {
    const { ifaceMngr, myShopApiStore } = store;
    return { ifaceMngr, myShopApiStore };
})
@observer
class LanguageSelect extends Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            menuAnchor: null,
            mobileModalIsOpen: false,
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    // setSelected = (lng) => {
    //     const { setI18nextLng } = this.props.ifaceMngr;
    //     document.body.dir = languageMap[lng].dir;
    //     i18next.changeLanguage(lng);
    //     setI18nextLng(lng);
    // }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) 
            this.setState({menuAnchor: null});
    }

    closeLngModal = () => {
       this.setState({mobileModalIsOpen: false, menuAnchor: null});
    } 

    render() {
        const { i18nextLng, languageMap, setI18nextLng } = this.props.ifaceMngr;
        const { setLng } = this.props.myShopApiStore;

        const mobileButton = {
            ...buttonStyle,
            ...{
               marginTop: '17px', 
               [document.body.dir === 'ltr'?'marginRight': 'marginLeft']: 0, 
               background: 'none', 
               color: 'var(--mainWhite)', 
               border: 'solid var(--mainBg) 1.9px',
            }
        }

        return (
            <>
            <div ref={this.wrapperRef} style={{zIndex: 2}}>
                  
                {
                    //!this.state.menuAnchor || this.props.isMobile
                    // ?  
                    <button onClick={({ currentTarget })  => this.setState({ menuAnchor: currentTarget,  mobileModalIsOpen: true})} 
                            style={this.props.isMobile?mobileButton:buttonStyle}>{languageMap[i18nextLng].label}
                    </button>
                    // : 
                    //     Object.keys(languageMap)?.map(item => (
                    //         <button onClick={() => {
                    //                 setI18nextLng(item);
                    //                 this.setState({menuAnchor: null});
                    //                 setLng(item);
                    //             }}
                    //             style={itemButton}
                    //             key={item}
                    //             >{languageMap[item].label}
                    //         </button>
                    //     ))
                }
                
            </div>
            <LanguageModal isOpen={this.state.mobileModalIsOpen} 
                           closeModal={this.closeLngModal} 
                           languageMap={languageMap} 
                           setI18nextLng={setI18nextLng} 
                           setLng={setLng} 
                           i18nextLng={i18nextLng} />
          
            </>
        );
    }
  };

export default LanguageSelect;
