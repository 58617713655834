import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class MenuItem extends React.Component {
    constructor(props) {
        super(props)
        this.scrollUpRef = React.createRef();
        this.state = {
             subcatChosenId: null
        }
    }

    componentDidMount() {
        this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})
    }
    handleSubcatChosen = async (id, subcat) => {
        const { index } = this.props;
        const { getItemList, initItemList, setSearchValue,
                setSubcatItemList, getSearchProducts } = this.props.myShopApiStore;

        //initItemList();
        setSubcatItemList(null);
        /*
        const searchProducts = await getSearchProducts("",subcat.parent_id)
        console.log(index, id, subcat.codename);

        const subcatItemList = searchProducts.filter(item => item.product.subCategory === subcat.codename);
        setSubcatItemList(subcatItemList.length ? subcatItemList : null)
        */
        getItemList(index, true, subcat.codename);
        setSearchValue('', true);
        this.state.subcatChosenId !== id && this.setState({subcatChosenId: id})
    }

    handleCatChosen = () => {
        const { index } = this.props;
        const { getItemList, setSearchValue, setSubcatItemList } = this.props.myShopApiStore;
        setSubcatItemList(null);
        getItemList(index,true);
        setSearchValue('', true);
        this.state.subcatChosenId !== null && this.setState({subcatChosenId: null})
    }
    
    render() {
        
        const { selectedCategoryIdx, searchValue, shop } = this.props.myShopApiStore;
        const { item, index } = this.props

        // console.log("item:", item)
        return (
            <div className={style.Container}>
                <div className={style.TopDiv}>
                    <div className={style.Cat} data-ind={index} data-code={item.codename} onClick={this.handleCatChosen} {...(selectedCategoryIdx===index?{ref:this.scrollUpRef}:{})} >
                        <div className={selectedCategoryIdx==index && !searchValue ? `${style.ItemIcon} ${style.ItemIconSelected}`:style.ItemIcon}>
                            <img className={style.ItemImg} src={item.icon} alt={item.name}/>
                        </div>
                        <div className={style.ItemName}>
                            {item.name}
                        </div>
                    </div>
                    <div className={style.Subcats}>
                        {
                            shop.subcategories && (selectedCategoryIdx==index && !searchValue)
                            ?
                                shop.subcategories.map(subcat => {
                                    {/* console.log("subcat:", subcat) */}
                                    return(
                                        subcat.parent_id === item.id && (typeof subcat.isActive == 'undefined' || subcat.isActive == '1')
                                        ?
                                            <div className={style.SubcatName} key={subcat.id}
                                                 style={{fontWeight: this.state.subcatChosenId === subcat.id ? 600 : null,
                                                         borderBottom: this.state.subcatChosenId === subcat.id ? ".07vw solid #fff" : null}} 
                                                 onClick={() => this.handleSubcatChosen(subcat.id, subcat)}
                                            >
                                                {subcat.name}
                                            </div>
                                        :
                                            null
                                    )
                                })
                            :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default MenuItem;
