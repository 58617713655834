import React from 'react';
import { observer, inject } from 'mobx-react';
import { api } from 'Configs/api';
// import ReactDOM from 'react-dom';
import style from './style.module.css';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class Maintenance extends React.Component {
    my_env = api.my_shop_api;
    render() {
        return (
            <div className={style.container}>
                <div id="" className={style.TopDiv}>
                    {
                        this.my_env.mStatus?
                            this.my_env.mText
                        : 'Server is currently in Maintenance. Welcome later.'
                    }
                </div>
            </div>
        )
    }
}

export default Maintenance;
