import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import CreditErrorModal from "./CreditErrorModal";
import LoadErrorModal from "./LoadErrorModal";
import ThanksModal from "../../ThanksModal";
import ThanksCouponModal from "../../ThanksCouponModal";
import APILoader from "../../APILoader";
import MakeOrderErrorModal from "../MakeOrderErrorModal";
import ValidSMSModal from "../../ValidSMSModal";

import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import CreditIframe from "./CreditIframe";
import { withTranslation } from "react-i18next";

import ReactPixel from 'react-facebook-pixel';

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})
@observer
class CreditDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
    this.parentRef = React.createRef();
    this.months = [
      { 1: "01" },
      { 2: "02" },
      { 3: "03" },
      { 4: "04" },
      { 5: "05" },
      { 6: "06" },
      { 7: "07" },
      { 8: "08" },
      { 9: "09" },
      { 10: "10" },
      { 11: "11" },
      { 12: "12" },
    ];
    this.currYear = new Date().getFullYear();
    this.years = Array.from(Array(15), (_, x) => x + this.currYear);
    this.state = {
      errorModalIsOpen: false,
      thanksModalIsOpen: false,
      apiLoaderIsOpen: false,
      makeOrderErrorModalIsOpen: false,
      makeOrderErrorModalErrorCode: '',
      makeOrderErrorModalErrorMessage: '',
      sessionUrl: "",
      iframeIsOpen: true,
      errors: [],
    };
  }

  handleCheckPreorderClick = async () => {
    const { checkPreorderResp } = this.props.myShopApiStore;
    await checkPreorderResp();
  }


  
  componentWillUnmount() {
    this.props.myShopApiStore.setDeliveryCost(0);
    this.props.myShopApiStore.calcTotalCartPrice();
  }

  errorConditions = () => {
    const { creditParams } = this.props.myShopApiStore;
    let errors = [];
    if (creditParams.id.trim() === "") {
      errors.push("id");
    }
    if (creditParams.cc_number.trim() === "") {
      errors.push("cc_number");
    }
    if (creditParams.cvv.trim() === "") {
      errors.push("cvv");
    }
    if (creditParams.year.trim() === "" || creditParams.year.trim() === "שנה") {
      errors.push("year");
    }
    if (
      creditParams.month.trim() === "" ||
      creditParams.month.trim() === "חודש"
    ) {
      errors.push("month");
    }

    return errors;
  };

  handleIframeCreditPaymentCall = async () => {
    const { makeOrderResp, orderData } = this.props.myShopApiStore;
    this.openAPILoader();
    const resp = await makeOrderResp();
    this.setState({iframeIsOpen: false});
    if (resp?.data?.errorCode === "0") {
      // finish with openning thanks modal
      if(process.env.REACT_APP_FACEBOOK_PIXEL !== '') ReactPixel.track('Purchase');

      this.openThanksModal();
    }
    else{
      this.setMakeOrderErrorModalParams(resp?.data?.errorCode, resp?.data?.errorMessage);
      this.openMakeOrderErrorModal();
    }
    this.closeAPILoader();
  };

  getFormattedExpDate = () => {
    // MMYY Format
    const { creditParams } = this.props.myShopApiStore;
    const y = creditParams.year; // YYYY
    const m = creditParams.month; // MM

    let formated = m + (y % 100); //MMYY (string)
    return formated;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { setCreditParams } = this.props.myShopApiStore;

    if (name === "cvv" || name === "cc_number" || name === "id") {
      !value.match(/\D/) && setCreditParams(name, value);
    } else {
      setCreditParams(name, value);
    }

    let errors = this.state.errors;
    const eIndex = errors.indexOf(name);
    if (eIndex > -1) {
      errors.splice(eIndex, 1);
      this.setState({
        errors: errors,
      });
    }
  };

  closeMakeOrderErrorModal = () => {
    this.setState({makeOrderErrorModalIsOpen: false})
  }

  openMakeOrderErrorModal = () => {
    this.setState({makeOrderErrorModalIsOpen: true})
  }

  setMakeOrderErrorModalParams = (makeOrderErrorModalErrorCode, makeOrderErrorModalErrorMessage) => {
    this.setState({
      makeOrderErrorModalErrorCode: makeOrderErrorModalErrorCode,
      makeOrderErrorModalErrorMessage: makeOrderErrorModalErrorMessage
    })
  }

  openAPILoader = () => {
    this.setState({ apiLoaderIsOpen: true });
  };

  closeAPILoader = () => {
    this.setState({ apiLoaderIsOpen: false });
  };

  openErrorModal = () => {
    this.setState({ errorModalIsOpen: true });
  };

  closeLoadErrorModal = () => {
    const { setSessionUrl } = this.props.myShopApiStore;
    setSessionUrl('');
    this.setState({sessionUrl: ''})
    this.props.prevStep();
  };

  closeErrorModal = () => {
    this.setState({ errorModalIsOpen: false });
  };

  openThanksModal = () => {
    this.setState({ thanksModalIsOpen: true });
  };

  closeThanksModal = () => {
    this.setState({ thanksModalIsOpen: false });
  };

  componentDidUpdate(prevProps, prevState) {
      // console.log("iframeRef:", this.iframeRef)
  }

  closeIframe = () => {
      if(this.iframeRef.current){
          // this.iframeRef.current.hidden ? this.iframeRef.current.hidden = false : this.iframeRef.current.hidden = true;
          this.iframeRef.current.contentWindow.close();
      }
  }
  
  componentDidMount = () => {
    if(this.state.iframeIsOpen){
      window.onmessage = e => {
        if (e.data === 'close-iframe') {
          this.handleIframeCreditPaymentCall();
        }
      }
    }
  }

  handleBackBtn = () => {
    const { setSessionUrl } = this.props.myShopApiStore;
    setSessionUrl('');
    this.setState({sessionUrl: ''})
    this.props.prevStep();
  }

  render() {
    const {
      creditParams,
      resetCreditParams,
      orderData,
      mainColor,
      sessionUrl,
      hasPostCoupon
    } = this.props.myShopApiStore;

    if (this.props.currentStep !== 3) {
      return null;
    } else {
      this.props.myShopApiStore.calcTotalCartPrice();
    }

    const { t } = this.props;

    return (
      <div className={style.Container} ref={this.parentRef}>
        <div
          className={style.CreditPayTitle}
          style={{ backgroundColor: mainColor}}
        >
          {t('credit card')}
        </div>
        {
          sessionUrl && sessionUrl !== "ERROR"
          ?
            <>
              <CreditIframe iframeRef={this.iframeRef} parentRef={this.parentRef} display={(this.state.iframeIsOpen ? 'block' : 'none')} />
              <button type="button" 
                      style={{backgroundColor: 'unset', border: '2px solid rgb(255, 255, 255, 0.3)', display: (this.state.iframeIsOpen ? 'block' : 'none')}}
                      onClick={this.handleBackBtn}>{t('back')}</button>
            </>
          :
            null
        }
        <LoadErrorModal
          closeModal={this.closeLoadErrorModal}
          modalIsOpen={sessionUrl === "ERROR"}
        />
        <CreditErrorModal
          closeModal={this.closeErrorModal}
          modalIsOpen={this.state.errorModalIsOpen}
        />
        {
          hasPostCoupon
          ?
            <ThanksCouponModal closeModal={this.closeThanksModal} modalIsOpen={this.state.thanksModalIsOpen} />
          :
            <ThanksModal closeModal={this.closeThanksModal} modalIsOpen={this.state.thanksModalIsOpen} />
        }
        <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
        <MakeOrderErrorModal 
          modalGoBack={true}
          closeModal={this.closeMakeOrderErrorModal} 
          modalIsOpen={this.state.makeOrderErrorModalIsOpen} 
          makeOrderErrorModalErrorCode={this.state.makeOrderErrorModalErrorCode} 
          makeOrderErrorModalErrorMessage={this.state.makeOrderErrorModalErrorMessage}
          openBasket={this.props.openBasket}
        />
      </div>
    );
  }
}

export default withTranslation()(CreditDetailsForm);
