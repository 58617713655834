import React, { Component, Suspense } from "react";
import style from "./style.module.css";
import { observer, inject } from "mobx-react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { withTranslation } from "react-i18next";
import 'react-lazy-load-image-component/src/effects/blur.css';
import bigappslogo from '../../../assets/bigappslogo.png'
import apple from '../../../assets/apple.png'
import android from '../../../assets/android.png'


const menuBtns = [ 'PPolicy', 'RetPolicy', 'Terms', 'Contacts',  'Accessibility'];

const menuBtnsFalafelZikhron = [ 'PPolicy', 'RetPolicy', 'Terms', 'WorkingMode', 'Accessibility' ];

@inject((store) => {
  const { ifaceMngr, myShopApiStore } = store;
  return { ifaceMngr, myShopApiStore };
})
@observer
class Footer extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      modalIsOpen: true, //welcome modal
      carouselModalIsOpen: true,
      isMobile: window.innerWidth <= 850,
      basketIsOpen: false,
    };
  }

  render() {

    const { setActiveTopMenu, activeTopMenu } = this.props.ifaceMngr
    const { userId, shop, shopData, getAccessibility, setSearchValue, my_env, __mobx_sync_versions__ } = this.props.myShopApiStore;
    const { t } = this.props;
    return (
      <div className={style.Container}>
        <div className={style.VersionDiv}>
          v{__mobx_sync_versions__.__mobx_sync_this__}
        </div>
        <div className={style.MenuBtns}>
        {
            (my_env.url.indexOf("falafelzikhron") >= 0 ? menuBtnsFalafelZikhron : menuBtns).map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        {(item === 'History' && userId === null) || (item === 'Accessibility' && (shopData == null || !shopData['accessibilityMessage'])) 
                        ?
                            null
                        :
                            <div key={`ind_${index}`} onClick={() => setSearchValue('', true)}>
                                <div className={activeTopMenu === item ? `${style.menubtn} ${style.menubtnActive}` : style.menubtn} 
                                onClick={() => setActiveTopMenu(item)} >{t(item)}</div>
                            </div>
                        }
                    </React.Fragment>
                )
            })
        }
        </div>
        <div className={style.BigappsLogo} onClick={()=> window.open("https://my-shop.co.il/", "_blank")}>
            <LazyLoadImage
              src={bigappslogo}
              alt="my-shop"
              width="100%"
            />
        </div>
        <div className={style.MobileBtns}>
          {
            shop.linkGoogle
            ?
              <LazyLoadImage
                src={android}
                alt="android"
                width="100%"
                onClick={()=> window.open(shop.linkGoogle, "_blank")}
              />
            :
              ''
          }
          {
            shop.linkApple
            ?
              <LazyLoadImage
                src={apple}
                alt="apple"
                width="100%"
                onClick={()=> window.open(shop.linkApple, "_blank")}
              />
            :
              ''
          }
        </div>
      </div>
    )
  }
}
export default withTranslation()(Footer);