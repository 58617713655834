import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import NumberFormat from 'react-number-format';
import style from './style.module.css';
import CarouselModal from 'Components/MainScreen/AppBody/ShopMenu/ItemList/Item/CarouselModal';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class Item extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            carouselModalIsOpen: false
        }
        this.curr = '₪'
    }

    closeCarouselModal = () => {
        this.setState({carouselModalIsOpen: false})
    }

    openCarouselModal = () => {
        this.setState({carouselModalIsOpen: true})
    }

    render() {
        const { mainColor, shop } = this.props.myShopApiStore;
        const { item, t } = this.props;

        const imageBig = item.shopProduct ? item.shopProduct.product.imageBig : null;
        return (
            <div className={style.Container}>
                <div className={style.modals}>
                    {
                        imageBig && typeof imageBig !== 'string' && imageBig.length && <CarouselModal modalIsOpen={this.state.carouselModalIsOpen} closeModal={this.closeCarouselModal} item={item.shopProduct}/>
                    }
                </div>
                <div className={style.RightDiv}>
                    <div className={style.Image}><img src={item.shopProduct.product.image} alt='' /></div>
                    {/* <div className={style.Tag}>טאגג</div> */}
                </div>
                <div className={style.CenterDiv}>
                    <div className={style.Name} style={{color: mainColor}}>{item.shopProduct.product.name}</div>
                    <div className={style.CenterBottomDiv}>
                        {   !shop.withoutPrices && 
                            <div className={style.ItemCost}> 
                                <NumberFormat value={item.price} prefix={t('currency')} decimalScale={2} fixedDecimalScale={true} displayType={'text'} /> 
                                {' ' + t('per')} {t(item.unitType)}
                            </div>
            }
                        {
                            !item.comment  
                                ? null 
                                : <div className={style.Comment}>{`${t('comment')} ${item.comment}`}</div>
                        }
                    </div>
                </div>
                <div className={style.LeftDiv}>
                    <div className={style.Amount}>{item.amount} {t(item.unitType)}</div>
                    <div className={style.TotalCost}>{!shop.withoutPrices && <NumberFormat value={item.total} prefix={t('currency')} decimalScale={2} fixedDecimalScale={true} displayType={'text'} />}</div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Item);