import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import ValidSMSModal from '../ValidSMSModal';
import ThanksCouponModal from '../ThanksCouponModal'

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            modalIsOpen: false,
            thanksCouponIsOpen: false
        }
    }
    
    closeThanksCouponModal = () => {
        this.setState({thanksCouponIsOpen: false});
    }

    componentDidMount = async () => {
        this.setState({
            errors: []
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
    
        this.props.myShopApiStore.setUserParams(name, value);

        let errors = this.state.errors;
        const eIndex = errors.indexOf(name);
        if (eIndex > -1) {
            errors.splice(eIndex, 1);
            this.setState({
                errors: errors,
                isButtonDisabled: false
            });
        }
    };

    closeModal = () => {
        this.setState({modalIsOpen: false})
    }

    openModal = () => {
        this.setState({modalIsOpen: true})
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleSubmit = () => {
        let errors = [];
        const notReq = ['email', 'entranceCode', 'floorNumber', 'apartmentNumber']
        const { userParams } = this.props.myShopApiStore;
        for (let param in userParams) {
            if(userParams[param].trim() === '' && !notReq.includes(param)) {
                errors.push(param);
            }
            else if(param === 'email' && userParams[param].trim() !== '' && !this.validateEmail(userParams[param].trim())){
                errors.push(param);
            }
            else if(param === 'phone' && !(/^\d+$/.test(userParams[param])) && param.length !== 10){
                errors.push(param);
            }
            else if(param === 'streetNumber' && !(/^\d+$/.test(userParams[param]))){
                errors.push(param);
            }
        }

        this.setState({
            errors: errors
        });

        if(errors.length == 0) {
            this.setState({isButtonDisabled: true});
            setTimeout(() => this.setState({isButtonDisabled: false}),4000);
            
            this.props.myShopApiStore.registerUser();
            this.openModal();
        }
    }

    render() {
        const { t } = this.props;
        return (
        <div className={style.container}>
            <ThanksCouponModal modalIsOpen={this.state.thanksCouponIsOpen} closeModal={this.closeThanksCouponModal}/>
            <div className={style.Header}>
            {t('Hot Welcome')}<br/> {t('Will be happy')}
            </div>
            <div className={style.Form}>
                <div className={style.FormLine}>
                    <div className={style.FormLineOne}>
                        <input 
                            type="text"
                            className = {this.state.errors.includes("name") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                            value={this.props.myShopApiStore.userParams.name} 
                            name="name"
                            onChange={this.handleChange} 
                            placeholder={t('full name')}
                        />
                    </div>
                </div>
                <div className={style.FormLine}>
                    <div className={style.FormLineOne}>
                        <input 
                            type="tel"
                            className = {this.state.errors.includes("phone") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                            value={this.props.myShopApiStore.userParams.phone} 
                            name="phone"
                            onChange={this.handleChange} 
                            placeholder={t('mobile phone')}
                        />
                    </div>
                </div>
                <div className={style.FormLine}>
                    <div className={style.FormLineOne}>
                        <input 
                            type="email"
                            className = {this.state.errors.includes("email") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                            value={this.props.myShopApiStore.userParams.email} 
                            name="email"
                            onChange={this.handleChange} 
                            placeholder={t('e-mail')}
                        />
                    </div>
                </div>
                <div className={style.FormLine}>
                    <div className={style.FormLineOne}>
                        <input 
                            className = {this.state.errors.includes("city") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                            value={this.props.myShopApiStore.userParams.city} 
                            name="city"
                            onChange={this.handleChange} 
                            placeholder={t('city / town')}
                        />
                    </div>
                </div>
                <div className={style.FormLine}>
                    <div className={style.FormLineOne}>
                        <input 
                            className = {this.state.errors.includes("streetName") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                            value={this.props.myShopApiStore.userParams.streetName} 
                            name="streetName"
                            onChange={this.handleChange} 
                            placeholder={t('street')}
                        />
                    </div>
                </div>
                <div className={style.FormLine}>
                    <div className={style.FormLineTwo}>
                        <input 
                            className = {this.state.errors.includes("streetNumber") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                            value={this.props.myShopApiStore.userParams.streetNumber} 
                            name="streetNumber"
                            onChange={this.handleChange} 
                            placeholder={t('no. home')}
                        />
                    </div>
                    <div className={style.FormLineTwo}>
                        <input 
                            className = {this.state.errors.includes("entranceCode") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                            value={this.props.myShopApiStore.userParams.entranceCode} 
                            name="entranceCode"
                            onChange={this.handleChange} 
                            placeholder={t('_entry code')}
                        />
                    </div>
                </div>
                <div className={style.FormLine}>
                    <div className={style.FormLineTwo}>
                        <input 
                            className = {this.state.errors.includes("apartmentNumber") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                            value={this.props.myShopApiStore.userParams.apartmentNumber} 
                            name="apartmentNumber"
                            onChange={this.handleChange} 
                            placeholder={t('flat number')}
                        />
                    </div>
                    <div className={style.FormLineTwo}>
                        <input 
                            className = {this.state.errors.includes("floorNumber") ? `${style.FormInput} ${style.FormInputWrong}` : style.FormInput}
                            value={this.props.myShopApiStore.userParams.floorNumber} 
                            name="floorNumber"
                            onChange={this.handleChange} 
                            placeholder={t('floor')}
                        />
                    </div>
                </div>
            </div>
            <div className={style.RegisterButton} style={{backgroundColor: this.state.isButtonDisabled ?  'rgb(0, 0, 0, 0.2)' : this.props.myShopApiStore.mainColor}} onClick={this.state.isButtonDisabled ? null : this.handleSubmit}>
                {t('registration')}
            </div>
            {//this.state.modalIsOpen && 
                <ValidSMSModal closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} action='Registration'/>}
        </div>
        )
    }
}

export default withTranslation()(Registration);